import { useQuery, UseQueryResult } from 'react-query';

import { apiService } from '$shared/services/apiService';
import { IWavesCapResponseData } from '$shared/services/types';

export const usePlutoPrice = (): UseQueryResult<IWavesCapResponseData> =>
    useQuery(['pluto-price'], () => apiService.getPlutoPriceDetails());

export const usePlutoQuantity = (): UseQueryResult<number> =>
    useQuery(['pluto-quantity'], () => apiService.getPlutoQuantity());

export const usePlutoTreasuryValue = (): UseQueryResult<number> =>
    useQuery(['treasury-value'], () => apiService.getPlutoTreasuryValue());
