import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { getAssetID } from '$shared/domain/constants';
import { apiService } from '$shared/services/apiService';
import { PLUTO_DECIMALS } from '$shared/constants';
import { IAssetFullDetails } from '$shared/services/types';

import imgSuccess from '$/assets/img/success.png';
import authService from '$/services/authentication/AuthenticationService';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import { Title } from '$/components/Pluto/Layout';
import { Button } from '$/components/Pluto/UI';
import { Statistics, TokenControl } from '$/components/Pluto';

import st from './IssuePlutoCard.module.scss';

interface IProps {
    className?: string;
    onClose: () => void;
    defaultSelectedToken: IAssetFullDetails;
    lpTokens: IAssetFullDetails[];
    onUpdate: () => void;
}

const IssuePlutoCard: FC<IProps> = ({ className, defaultSelectedToken, onUpdate, lpTokens, onClose }) => {
    const { t } = useTranslation();
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [sendingTransaction, setSendingTransaction] = useState(false);

    const [sendValue, setSendValue] = useState(0);
    const [receiveValue, setReceiveValue] = useState(0);
    const [plutoOnboardingParameters, setPlutoOnboardingParameters] = useState({
        growthFactor: 0,
        premium: 0,
        period: 0,
        teamAllocation: 0,
        buybackRatio: 0,
        apy: 0,
    });

    const plutoAssetDetails = lpTokens.find((token) => token.assetId === getAssetID('PLUTO'));

    const [tokens, setTokens] = useState({
        send: defaultSelectedToken,
        receive: plutoAssetDetails,
    });

    const handleSetValues = useEffectiveCallback(() => (val) => {
        let correctedVal = val;
        if (val.length > 1 && val[1] !== '.' && val[0] === '0') {
            correctedVal = val.replace(/^0/, '');
        }
        let toSet = correctedVal;
        if (Number.isNaN(parseFloat(correctedVal))) {
            toSet = '';
        }
        setSendValue(toSet);
    });

    const handleIssue = useEffectiveCallback(() => {
        if (tokens && tokens.send !== undefined) {
            const paymentAmount = Math.trunc(sendValue * 10 ** tokens.send.decimals);
            setSendingTransaction(true);

            authService
                .issuePluto(tokens.send, paymentAmount)
                .then((res) => {
                    console.log(res);

                    onUpdate();

                    setSendingTransaction(false);
                    setIsSuccessful(true);
                })
                .catch((error) => {
                    console.error(error);

                    setSendingTransaction(false);
                    setIsSuccessful(false);

                    if (error.message) {
                        alert(error.message);
                    } else {
                        alert(error.toString());
                    }
                });
        }
    });

    useEffect(() => {
        apiService
            .getPotentialPluto(tokens.send.assetId, Math.trunc(sendValue * 10 ** tokens.send.decimals))
            .then((res) => {
                setReceiveValue(res.result.value['_2'].value);
            })
            .catch(console.error);
    }, [tokens, sendValue]);

    useEffect(() => {
        apiService
            .getPlutoOnboardingParameters()
            .then((res) => {
                setPlutoOnboardingParameters(res);
            })
            .catch(console.error);
    }, []);

    const receiveFloat = receiveValue / PLUTO_DECIMALS;
    let receiveOne = receiveValue / PLUTO_DECIMALS / sendValue || 0;
    receiveOne = Number.isNaN(receiveOne) ? 0 : receiveOne;

    return (
        <div className={cn(st.issuePluto, className)}>
            {!isSuccessful && !sendingTransaction && (
                <>
                    <Title as="h3" size="h2" className={st.title}>
                        {t('Select the LP token for which you will issue PLUTO')}
                    </Title>

                    <TokenControl
                        withChooseToken
                        withMax
                        lpTokens={lpTokens}
                        balance={0}
                        defaultSelectedToken={defaultSelectedToken}
                        value={sendValue}
                        setValue={handleSetValues()}
                        setToken={(token) => {
                            setTokens({
                                ...tokens,
                                send: token,
                            });
                        }}
                    />

                    <p className={st.exchangeRate}>
                        1 {tokens.send.name} = {receiveOne.toFixed(2)} PLUTO
                    </p>

                    <TokenControl
                        lpTokens={lpTokens}
                        defaultSelectedToken={plutoAssetDetails}
                        apy={plutoOnboardingParameters.apy}
                        readOnly
                        premium={plutoOnboardingParameters.premium}
                        period={plutoOnboardingParameters.period}
                        balance={plutoAssetDetails ? plutoAssetDetails.balance / 10 ** plutoAssetDetails.decimals : 0}
                        value={receiveFloat}
                        setValue={handleSetValues()}
                    />

                    <Statistics
                        className={st.stats}
                        items={[
                            {
                                title: t('Transaction fee'),
                                value: '0.005 WAVES',
                            },
                        ]}
                    />

                    <Button fluid theme="orange" className={st.btn} onClick={handleIssue}>
                        {t('Issue')}
                    </Button>

                    <p className={st.warning}>
                        {t('Your PLUTO will be immediately issued and staked.')}
                        <br />
                        {t('It will unlock after {{period}} days.', {
                            period: plutoOnboardingParameters.period,
                        })}
                    </p>
                </>
            )}

            {sendingTransaction && <p className={st.successText}>{t('Sending your transaction. Please wait...')}</p>}

            {isSuccessful && (
                <>
                    <img className={st.successImg} src={imgSuccess} alt="" />

                    <p className={st.successText}>{t('Your tokens have been converted to PLUTO successfully')}</p>

                    <Button fluid theme="orange" className={st.btn} onClick={onClose}>
                        {t('Good')}
                    </Button>
                </>
            )}
        </div>
    );
};

export default IssuePlutoCard;
