import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { IAssetFullDetails, IPlutoOnboardingParameters } from '$shared/services/types';

import icoInfo from '$/assets/sprite/info.svg';
import { useToggleOpen } from '$/hooks/useToggleOpen';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import { separateThousands, toDollars, toFixed2 } from '$/utils/formatNumber';
import { Button, Tag, Tooltip } from '$/components/Pluto/UI';
import { Icon, IssuePlutoCard } from '$/components/Pluto';
import Modal from '$/components/Pluto/Modal';

import { gertLogoSrc } from './config';
import st from './TokenCard.module.scss';

interface IProps {
    className?: string;
    isLoadingPlaceholder?: boolean;
    assetDetails: IAssetFullDetails;
    lpTokens: IAssetFullDetails[];
    plutoOnboardingParameters: IPlutoOnboardingParameters;
    onUpdate: () => void;
}

const TokenCard: FC<IProps> = ({
    assetDetails,
    assetDetails: { assetId, balance, decimals, imgSrc, name, price, userBalancePrice },
    className,
    isLoadingPlaceholder,
    lpTokens,
    plutoOnboardingParameters: { apy, period, premium },
    onUpdate,
}) => {
    const { t } = useTranslation();

    const { isOpen, setOpen } = useToggleOpen();

    const handleShowModal = useEffectiveCallback(() => setOpen(true));
    const handleHideModal = useEffectiveCallback(() => setOpen(false));

    return (
        <div className={cn(st.card, className)}>
            <Tag className={st.tag}>
                <span>
                    {t('Onboarding')} APY {isLoadingPlaceholder ? '...' : separateThousands(apy)}%
                </span>

                <Tooltip className={st.tooltip} position="top" target={<Icon size="x-small" item={icoInfo} />}>
                    <p>
                        {`ROI in ${period} days:`}
                        &nbsp;<b>{`${premium / 100} %`}</b>
                    </p>
                </Tooltip>
            </Tag>

            <div className={st.header}>
                <img src={gertLogoSrc(imgSrc, assetId)} alt="" className={st.logo} />

                <div>
                    <p className={st.title}>{name}</p>
                    <p>{isLoadingPlaceholder ? '...' : toDollars(price)}</p>
                </div>
            </div>

            <div className={st.bottom}>
                <p className={st.balance}>{t('Balance')}</p>

                <p>
                    <span className={st.value}>
                        {isLoadingPlaceholder ? '...' : separateThousands(toFixed2(balance / 10 ** decimals))}
                    </span>
                </p>

                <p className={st.dollars}>{isLoadingPlaceholder ? '...' : toDollars(userBalancePrice)}</p>

                {!isLoadingPlaceholder && (
                    <Button theme="orange" size="small" className={st.btn} onClick={handleShowModal}>
                        {t('Issue PLUTO')}
                    </Button>
                )}
            </div>

            <Modal withCloseBtn isShown={isOpen} onClose={handleHideModal}>
                <IssuePlutoCard
                    onClose={handleHideModal}
                    onUpdate={onUpdate}
                    lpTokens={lpTokens}
                    defaultSelectedToken={assetDetails}
                />
            </Modal>
        </div>
    );
};

export default TokenCard;
