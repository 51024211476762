import { useQuery, UseQueryResult } from 'react-query';

import { apiService } from '$shared/services/apiService';
import { getAssetID } from '$shared/domain/constants';
import { IAssetBalance, IAssetBlockchainDetails, IWithPrice } from '$shared/services/types';

import authService from '$/services/authentication/AuthenticationService';

export const useUserLpTokens = (): UseQueryResult<Array<IAssetBalance & IWithPrice & IAssetBlockchainDetails>> =>
    useQuery(['user-lp-tokens'], () => apiService.getUserLpTokens(authService.getAddress()));

export const useCurrentHeight = (): UseQueryResult<any> =>
    useQuery(['current-height'], () => apiService.getCurrentHeight());

export const useUserLpTokensPrice = (): number => {
    const { data: assetsWithPrices = [] } = useUserLpTokens();

    return assetsWithPrices
        .filter((assetWithPrices) => assetWithPrices.assetId !== getAssetID('PLUTO'))
        .reduce((acc, assetDetails) => acc + assetDetails.userBalancePrice, 0);
};
