import { FC } from 'react';
import cn from 'classnames';

import st from './BaseContainer.module.scss';

interface IProps {
    flex?: boolean;
    className?: string;
}

const BaseContainer: FC<IProps> = ({ className, children, flex }) => (
    <main className={cn(st.main, { [st.flex]: flex }, className)}>{children}</main>
);

export default BaseContainer;
