import { FC } from 'react';

import { BuyPlutoPromo } from '$/components/Pluto';
import { Logo, SidebarBottom } from '$/components/Pluto/Layout';

import UserBlock from '../UserBlock';
import Navigation from '../Navigation';
import st from './SidebarContent.module.scss';

const SidebarContent: FC = () => (
    <>
        <Logo dark className={st.logo} />
        <UserBlock className={st.userBlock} />
        <Navigation className={st.navigation} />

        <SidebarBottom>
            <BuyPlutoPromo />
        </SidebarBottom>
    </>
);

export default SidebarContent;
