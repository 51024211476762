const MENU_ITEMS = [
    {
        title: 'Get PLUTO',
        to: 'getPluto',
    },
    {
        title: 'About PLUTO',
        to: 'aboutPluto',
    },
    {
        title: 'News',
        to: 'news',
    },
];

export default MENU_ITEMS;
