import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ErrorMessage } from '$/components/Pluto';

import imgEmpty from './img/empty.png';
import st from './EmptyList.module.scss';

interface IProps {
    className?: string;
}

const EmptyList: FC<IProps> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <div className={cn(st.emptyList, className)}>
            <ErrorMessage
                img={imgEmpty}
                text={t('Your active vestings will be displayed here after you issue PLUTO')}
            />
        </div>
    );
};

export default EmptyList;
