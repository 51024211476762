import type { IUrlsRecord } from '$shared/types';
import CommonUrlRecord from '$shared/structures/CommonUrlRecord';
import { isDevEnv } from '$shared/utils';

const hostnameContains = (part: string): boolean => window.location.hostname.indexOf(part) !== -1;

class FrontendUrlRecord extends CommonUrlRecord implements IUrlsRecord {
    DOMAIN_HOST = process.env.DOMAIN_HOST || window.location.hostname;

    get API_URL() {
        if (process.env.API_URL) {
            return process.env.API_URL;
        }

        if (isDevEnv() || !hostnameContains('pluto.gold')) {
            return `http://${this.DOMAIN_HOST}:8000`;
        }

        return `${this.DOMAIN_URL}/api`;
    }
}

export const {
    DOMAIN_HOST,
    BACKEND_SWOPFI_URL,
    DOMAIN_URL,
    NODE_URL,
    BUY_EGGS_URL,
    BUY_WAVES_URL,
    DEPOSIT_WAVES_URL,
    DATA_SERVICE_URL,
    SWOP_EXCHANGE_URL,
    API_URL,
    BLOCKCHAIN_URL,
    SCAN_URL,
    PYTHON_MICROSERVICE_URL,
    CACHE_READER_URL,
    CACHE_WRITER_URL,
    DUXPLORER_URL,
    RESERVE_DUXPLORER_URL,
} = new FrontendUrlRecord();
