import birthdayImg from './assets/birthday.svg';

const BIRTHDAY_NEWS_ID = 'birthday';

// eslint-disable-next-line import/prefer-default-export
export const newsData = [
    {
        id: BIRTHDAY_NEWS_ID,
        imgUrl: birthdayImg,
        date: '2022-04-09',
        title: `news.items.${BIRTHDAY_NEWS_ID}.title`,
        text: `news.items.${BIRTHDAY_NEWS_ID}.text`,
        link: {
            external: true,
            href: `news.items.${BIRTHDAY_NEWS_ID}.link.href`,
            text: `news.items.${BIRTHDAY_NEWS_ID}.link.text`,
        },
    },
];
