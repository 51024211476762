import { createElement, FC, ReactNode } from 'react';
import cn from 'classnames';

import getPromoClassName from './config';
import { TTitleSizes } from './types';
import st from './Title.module.scss';

interface IProps {
    promo?: boolean;
    as?: TTitleSizes | 'p' | 'span' | 'div';
    children?: ReactNode;
    className?: string;
    size?: TTitleSizes;
}

const Title: FC<IProps> = ({ promo, children, as = 'h1', size = 'h1', className, ...rest }) =>
    createElement(
        as,
        {
            className: cn({ [st[size]]: !promo }, { [st[getPromoClassName(size)]]: promo }, className),
            ...rest,
        },
        children,
    );

export default Title;
