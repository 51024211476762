import icoDashboard from '$/assets/sprite/dashboard.svg';
import icoList from '$/assets/sprite/list.svg';
import { PERSONAL_CABINET } from '$/constants';

const MENU_ITEMS = [
    {
        titleKey: 'personal-cabinet.menu.issue',
        ico: icoDashboard,
        tab: PERSONAL_CABINET.ISSUE_PLUTO,
        disabled: false,
        badge: null,
    },
    {
        titleKey: 'personal-cabinet.menu.issued',
        ico: icoList,
        tab: PERSONAL_CABINET.ISSUED_PLUTO,
        disabled: false,
        badge: null,
    },
    {
        titleKey: 'personal-cabinet.menu.governance',
        ico: null,
        tab: 'governance',
        disabled: true,
        badge: 'Soon',
    },
];

export default MENU_ITEMS;
