import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '$/components/Pluto/UI';
import { Title } from '$/components/Pluto/Layout';

import Statistics from '../Statistics';
import InfoPanel from '../InfoPanel';
import st from './CabinetHeader.module.scss';

const CabinetHeader: FC = () => {
    const { t } = useTranslation();

    return (
        <div className={st.cabinetHeader}>
            <InfoPanel className={st.infoPanel} />

            <div className={st.header}>
                <Title className={st.title}>{t('personal-cabinet.cabinet-header.title')}</Title>

                <Button
                    size="medium"
                    className={st.tradeBtn}
                    external
                    href="https://puzzleswap.org/trade?asset1=Ajso6nTTjptu2UHLx6hfSXVtHFtRBJCkKYd5SAyj7zf5"
                >
                    {t('personal-cabinet.cabinet-header.trade-btn')}
                </Button>
            </div>

            <Statistics />
        </div>
    );
};

export default CabinetHeader;
