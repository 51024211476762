import React, { FC } from 'react';
import cn from 'classnames';

import st from './Statistics.module.scss';

export interface IProps {
    className?: string;
    rowClassName?: string;
    items: Array<{ title: string; value: string }>;
}

const Statistics: FC<IProps> = ({ className, items, rowClassName }) => {
    const content = items.map(({ title, value }) => (
        <div key={title} className={cn(st.statistics, rowClassName)}>
            <p className={st.title}>{title}</p>
            <p>{value}</p>
        </div>
    ));

    if (className) {
        return <div className={className}>{content}</div>;
    }
    return <>{content}</>;
};

export default Statistics;
