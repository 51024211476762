import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import st from './LoadingTab.module.scss';

interface IProps {
    className?: string;
}

const LoadingTab: FC<IProps> = ({ className }) => {
    const { t } = useTranslation();

    return <div className={cn(st.emptyList, className)}>{t('Loading')}</div>;
};

export default LoadingTab;
