import React, { ReactNode } from 'react';
import { QueryClient } from 'react-query';

import ReactQueryProvider from './ReactQueryProvider';
import RecoilProvider from './RecoilProvider';

interface Props {
    queryClient: QueryClient;
    children: ReactNode;
}

const AppProvider = ({ queryClient, children }: Props): JSX.Element => (
    <ReactQueryProvider queryClient={queryClient}>
        <RecoilProvider>{children}</RecoilProvider>
    </ReactQueryProvider>
);

export default AppProvider;
