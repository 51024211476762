import { FC } from 'react';
import cn from 'classnames';

import st from './Progress.module.scss';

interface IProps {
    className?: string;
    progress?: number;
}

const Progress: FC<IProps> = ({ className, progress = 0, children }) => (
    <div className={cn(st.progress, className)}>
        <span className={st.track} style={{ width: progress < 100 ? `${progress}%` : '100%' }} />
        {children && <span className={st.legend}>{children}</span> }
    </div>
);

export default Progress;
