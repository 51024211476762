import { SCROLL_DURATION } from '$/constants';

import scrollToCoordinate from './scrollToCoordinate';

interface IScrollToSelector {
    selector: string;
    duration: number;
    offset: number;
}

const scrollToSelector = ({ selector, duration = SCROLL_DURATION, offset = 0 }: IScrollToSelector): void => {
    const target = document.querySelector(selector);

    if (target) {
        const coordinate = target.getBoundingClientRect().top + window.scrollY - offset;

        scrollToCoordinate({ coordinate, duration });
    }
};

export default scrollToSelector;
