import { SyntheticEvent } from 'react';

import { SCROLL_DURATION } from '$/constants';

import scrollToSelector from './scrollToSelector';

interface IScrollToAnchor {
    duration?: number;
    offset?: number;
}

const scrollToAnchor =
    (params: IScrollToAnchor = {}): ((event: SyntheticEvent) => void) =>
    (event: SyntheticEvent): void => {
        const { duration = SCROLL_DURATION, offset = 0 } = params;
        const target = (event.target as HTMLElement).getAttribute('href') || '';

        event.preventDefault();

        scrollToSelector({ selector: target, duration, offset });
    };

export default scrollToAnchor;
