import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Title } from '$/components/Pluto/Layout';

import st from './Statistics.module.scss';
import { apiService } from '$shared/services/apiService';
import { ILandingDetails } from '$shared/services/types';
import { separateThousands, toDollars } from '$/utils/formatNumber';

interface IProps {
    className?: string;
}

const Statistics: FC<IProps> = ({ className }) => {
    const { t } = useTranslation();

    const [landingParameters, setLandingParameters] = useState<ILandingDetails>({
        marketValue: 0,
        treasuryValue: 0,
        stakingApy: 0,
        date: new Date(),
    });

    useEffect(() => {
        apiService
            .getLandingParameters()
            .then((res) => {
                console.log(res);
                setLandingParameters(res);
            })
            .catch(console.error);
    }, []);

    return (
        <ul className={cn(st.statistics, className)}>
            <li className={st.item}>
                <Title as="p" className={st.title}>
                    {toDollars(landingParameters.treasuryValue / 10 ** 6, 0)}
                </Title>
                {t('home.statistics.treasury_size')}
            </li>
            <li className={st.item}>
                <Title as="p" className={st.title}>
                    {toDollars(landingParameters.marketValue / 10 ** 6, 0)}
                </Title>
                {t('home.statistics.market_capitalisation')}
            </li>
            <li className={st.item}>
                <Title as="p" className={st.title}>
                    {separateThousands(landingParameters.stakingApy / 10000)} %
                </Title>
                {t('home.statistics.staking_apy')}
            </li>
        </ul>
    );
};

export default Statistics;
