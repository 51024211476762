import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import icoArrowRight from '$/assets/sprite/arrow-right.svg';
import { ROUTES } from '$/constants';
import { Button } from '$/components/Pluto/UI';
import { Title, Wrap } from '$/components/Pluto/Layout';

import st from './GetPluto.module.scss';

interface IProps {
    className?: string;
    id?: string;
}

const GetPluto: FC<IProps> = ({ className, id }) => {
    const { t } = useTranslation();

    return (
        <section className={cn(st.content, className)} id={id}>
            <Wrap>
                <Title promo as="h2" className={st.mainTitle}>
                    <Trans i18nKey="home.ways.title" components={{ br: <br /> }} />
                </Title>

                <ul className={st.ways}>
                    <li className={st.way}>
                        <Title promo as="h3" size="h2" className={st.title}>
                            {t('home.ways.lp_tokens_title')}
                        </Title>

                        <Title promo as="p" size="h3" className={st.text}>
                            {t('home.ways.lp_tokens_text')}
                        </Title>

                        <Button size="medium" icon={icoArrowRight} href={ROUTES.AUTH}>
                            {t('home.get_pluto')}
                        </Button>
                    </li>

                    <li className={st.way}>
                        <Title promo as="h3" size="h2" className={st.title}>
                            {t('home.ways.pool_title')}
                        </Title>

                        <Title promo as="p" size="h3" className={st.text}>
                            {t('home.ways.pool_text')}
                        </Title>

                        <Button
                            size="medium"
                            icon={icoArrowRight}
                            href="https://waves.exchange/trading/spot/Ajso6nTTjptu2UHLx6hfSXVtHFtRBJCkKYd5SAyj7zf5_USDN"
                            external
                        >
                            {t('home.buy_pluto')}
                        </Button>
                    </li>
                </ul>
            </Wrap>
        </section>
    );
};

export default GetPluto;
