import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import icoArrowRight from '$/assets/sprite/arrow-right.svg';
import { Button } from '$/components/Pluto/UI';
import { Logo, Wrap } from '$/components/Pluto/Layout';

import st from './Footer.module.scss';

interface IProps {
    classNames?: string;
}

const Footer: FC<IProps> = ({ classNames }) => {
    const { t } = useTranslation();

    const currentTime = new Date();
    const copy = t('footer.copy', { value: currentTime.getFullYear() });

    return (
        <footer className={cn(st.footer, classNames)}>
            <Wrap>
                <div className={st.footerContent}>
                    <div className={st.logoContainer}>
                        <Logo className={st.logo} />

                        <p className={st.copy}>{copy}</p>
                    </div>

                    <div className={st.socialCol}>
                        <p className={st.colTitle}>{t('footer.social.title')}</p>

                        <a href="https://t.me/plutosoon" target="_blank" className={st.link} rel="noreferrer">
                            {t('footer.social.telegram')}
                        </a>

                        <a href="https://twitter.com/whenPluto" className={st.link} target="_blank" rel="noreferrer">
                            {t('footer.social.twitter')}
                        </a>

                        <a
                            href="https://mirror.xyz/0x409981e2C0370AA0790CCd4AC90F1143D2a01886"
                            className={st.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('footer.social.medium')}
                        </a>
                    </div>

                    <div className={st.documentsCol}>
                        <p className={st.colTitle}>{t('footer.documents.title')}</p>

                        <a
                            target="_blank"
                            href="https://mirror.xyz/0x409981e2C0370AA0790CCd4AC90F1143D2a01886/WU_eUFiXRHTFa-dCxsDeRNjFJLbF-awO47bjVUY7VTo"
                            className={st.link}
                            rel="noreferrer"
                        >
                            {t('footer.documents.what_is_pluto')}
                        </a>

                        {/*<a href="$/components/Pluto/Layout/Footer/index" className={st.link}>*/}
                        {/*    {t('footer.documents.doc')}*/}
                        {/*</a>*/}
                    </div>

                    {/*<Button theme="secondary" icon={icoArrowRight} className={st.getBtn}>*/}
                    {/*    {t('home.get_pluto')}*/}
                    {/*</Button>*/}

                    <p className={st.mobileCopy}>{copy}</p>
                </div>
            </Wrap>
        </footer>
    );
};

export default Footer;
