import cn from 'classnames';
import { FC } from 'react';

import st from './Tag.module.scss';

interface Props {
    className?: string;
}

const Tag: FC<Props> = ({ className, children }) => <div className={cn(st.tag, className)}>{children}</div>;

export default Tag;
