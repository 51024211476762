import { ProviderKeeper } from '@waves/provider-keeper';
import { Signer } from '@waves/signer';
import { ProviderWeb } from '@waves.exchange/provider-web';
import { ProviderCloud } from '@waves.exchange/provider-cloud';
import { Provider } from '@waves/signer/dist/cjs/types';

import { lStorage } from '$/utils/localStorage';
import { getAddress, getAssetID } from '$shared/domain/constants';
import { waitForTx } from '@waves/waves-transactions';
import { NODE_URL } from '$/constants';
import { apiService } from '$shared/services/apiService';
import { IAssetFullDetails } from '$shared/services/types';

export enum ProviderTypesEnum {
    Keeper = 'Keeper',
    Web = 'Web',
    Cloud = 'Cloud',
}

interface IAuthData {
    address: string;
    type: ProviderTypesEnum;
}

class AuthenticationService {
    static storageKey = '@auth/data';

    authData?: IAuthData;

    signer?: Signer;

    constructor() {
        const storageValue = lStorage.get(AuthenticationService.storageKey);
        try {
            this.authData = JSON.parse(storageValue) as IAuthData;
        } catch (e) {
            console.error(`Cannot parse storage value`, storageValue);
        }
        this.createSignerInstance(this.authData?.type);
    }

    createSignerInstance(type?: ProviderTypesEnum) {
        let providerType = type;
        if (!type && this.authData && this.authData.type) {
            providerType = this.authData.type;
        }
        this.signer = new Signer({
            NODE_URL,
        });
        if (!type) return;
        let provider: Provider;
        if (providerType === ProviderTypesEnum.Keeper) {
            provider = new ProviderKeeper();
        } else if (providerType === ProviderTypesEnum.Web) {
            provider = new ProviderWeb('https://waves.exchange/signer/');
        } else if (providerType === ProviderTypesEnum.Cloud) {
            provider = new ProviderCloud();
        } else {
            throw new Error(`Provider type is not supported`);
        }

        return this.signer.setProvider(provider).catch(console.error);
    }

    async startAuth(type: ProviderTypesEnum): Promise<IAuthData> {
        if (!this.signer) {
            throw new Error(`Signer is not initialized yet`);
        }
        await this.createSignerInstance(type);
        const user = await this.signer.login();
        const authData = {
            ...user,
            type,
        };
        lStorage.set(AuthenticationService.storageKey, JSON.stringify(authData));
        this.authData = authData;
        return authData;
    }

    getAddress() {
        if (this.authData) {
            return this.authData.address;
        }
        return undefined;
    }

    getUser() {
        return this.authData;
    }

    async issuePluto(toSend: IAssetFullDetails, amount: number) {
        const tx = {
            dApp: getAddress('PLUTO_MINT_DAPP'),
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'issuePluto',
                args: [
                    {
                        type: 'boolean',
                        value: false,
                    },
                ],
            },
            payment: [
                {
                    assetId: toSend.assetId === getAssetID('WAVES') ? null : toSend.assetId,
                    amount,
                },
            ],
        };

        // @ts-ignore
        let invokeTx = await this.signer.invoke(tx).broadcast();
        if (Array.isArray(invokeTx)) {
            // @ts-ignore
            invokeTx = invokeTx[0];
        }
        if (invokeTx) {
            // @ts-ignore
            return waitForTx(invokeTx.id, { apiBase: NODE_URL });
        }
        return Promise.reject(new Error('Cannot send tx'));
    }

    async stakePluto(amount: number) {
        const tx = {
            dApp: getAddress('PLUTO_STAKE_DAPP'),
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'stake',
                args: [],
            },
            payment: [
                {
                    assetId: getAssetID('PLUTO'),
                    amount,
                },
            ],
        };

        // @ts-ignore
        let invokeTx = await this.signer.invoke(tx).broadcast();
        if (Array.isArray(invokeTx)) {
            // @ts-ignore
            invokeTx = invokeTx[0];
        }
        if (invokeTx) {
            // @ts-ignore
            return waitForTx(invokeTx.id, { apiBase: NODE_URL });
        }
        return Promise.reject(new Error('Cannot send tx'));
    }

    async unstakePluto(amount: number) {
        const tx = {
            dApp: getAddress('PLUTO_STAKE_DAPP'),
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'unstake',
                args: [
                    {
                        type: 'integer',
                        value: amount,
                    },
                ],
            },
            payment: [],
        };

        // @ts-ignore
        let invokeTx = await this.signer.invoke(tx).broadcast();
        if (Array.isArray(invokeTx)) {
            // @ts-ignore
            invokeTx = invokeTx[0];
        }
        if (invokeTx) {
            // @ts-ignore
            return waitForTx(invokeTx.id, { apiBase: NODE_URL });
        }
        return Promise.reject(new Error('Cannot send tx'));
    }

    logout() {
        lStorage.set(AuthenticationService.storageKey, null);
        this.authData = undefined;
    }

    async getLoggedInUserPortfolio() {
        const userAddress = this.getAddress();
        if (userAddress) {
            return apiService.getPortfolio(userAddress);
        }
        return Promise.resolve([]);
    }

    async getAssetBalance(assetId: string) {
        const userPortfolio = await this.getLoggedInUserPortfolio();
        return userPortfolio.find((assetBalance) => assetBalance.assetId === assetId);
    }

    isLoggedIn() {
        return this.getAddress() !== undefined;
    }
}

const authService = new AuthenticationService();

export default authService;
