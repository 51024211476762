import { getAssetID } from '$shared/domain/constants';

import imgPlutoLpLogo from '$/assets/img/pluto-lp-image.png';
import imgPlutoLogo from '$/assets/img/pluto-logo.png';

// eslint-disable-next-line import/prefer-default-export
export const gertLogoSrc = (src: string, assetId: string): string => {
    if (assetId === getAssetID('PARENT_POOL_LP')) {
        return imgPlutoLpLogo;
    }

    if (assetId === getAssetID('PLUTO')) {
        return imgPlutoLogo;
    }

    return src;
};
