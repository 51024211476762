import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import icoClose from '$/assets/sprite/close.svg';
import { Icon } from '$/components/Pluto';
import { Overlay } from '$/components/Pluto/Layout';

import st from './Modal.module.scss';

interface IProps {
    isShown: boolean;
    withCloseBtn?: boolean;
    className?: string;
    onClose: () => void;
    container?: HTMLElement;
}

const Modal: FC<IProps> = ({ isShown, withCloseBtn, children, className, onClose, container = document.body }) => {
    const renderModalBody = () => {
        if (!isShown) {
            return null;
        }

        return (
            <Overlay className={st.overlay}>
                <div className={cn(st.content, className)}>
                    {children}

                    {withCloseBtn && (
                        <button type="button" className={st.closeBtn} onClick={onClose}>
                            <Icon item={icoClose} />
                        </button>
                    )}
                </div>
            </Overlay>
        );
    };

    return createPortal(renderModalBody(), container);
};

export default Modal;
