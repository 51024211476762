import { useQuery, UseQueryResult } from 'react-query';

import { IOnboarding, IPlutoOnboardingParameters, IWithAsset } from '$shared/services/types';
import { apiService } from '$shared/services/apiService';

import authService from '$/services/authentication/AuthenticationService';

export const usePlutoOnboardingParameters = (): UseQueryResult<IPlutoOnboardingParameters> =>
    useQuery(['onboarding-parameters'], () => apiService.getPlutoOnboardingParameters());

export const useOnboardindsList = (): UseQueryResult<Array<IOnboarding & IWithAsset>> => {
    const address = authService.getAddress();

    return useQuery(['onboardinds-list', address], () => apiService.getOnboardings(address || ''), {
        enabled: Boolean(address),
    });
};
