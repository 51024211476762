import { VFC } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import getUrl from '$/utils/getUrl';
import { ROUTES } from '$/constants';
import { Badge, Icon } from '$/components/Pluto';

import MENU_ITEMS from './config';
import st from './Navigation.module.scss';

interface Props {
    className?: string;
}

const Navigation: VFC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <nav className={className}>
            {MENU_ITEMS.map(({ tab, titleKey, ico, disabled, badge }) => (
                <NavLink
                    activeClassName={st.active}
                    className={cn(st.link, { [st.disabled]: disabled })}
                    key={titleKey}
                    to={getUrl(ROUTES.DASHBOARD_INNER, { tab })}
                >
                    {ico && <Icon size="medium" item={ico} className={st.icon} />}
                    {badge && <Badge className={st.badge}>{badge}</Badge>}
                    {t(titleKey)}
                </NavLink>
            ))}
        </nav>
    );
};

export default Navigation;
