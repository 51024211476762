import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import icoArrowRight from '$/assets/sprite/arrow-right.svg';
import { Button } from '$/components/Pluto/UI';
import { Title, Wrap } from '$/components/Pluto/Layout';

import imgPlutoText from './img/pluto-text.svg';
import imgBg from './img/bg.png';
import imgBgMobile from './img/bg-mobile.png';
import st from './Intro.module.scss';
import { ROUTES } from '$/constants';

interface IProps {
    className?: string;
}

const Intro: FC<IProps> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <section className={cn(st.intro, className)}>
            <div className={st.introContent}>
                <Wrap>
                    <img src={imgPlutoText} alt="Pluto" className={st.pluto} />

                    <Title promo size="h2" className={st.title}>
                        <Trans i18nKey="home.intro" components={{ br: <br /> }} />
                    </Title>

                    <Button icon={icoArrowRight} href={ROUTES.AUTH}>
                        {t('home.get_pluto')}
                    </Button>
                </Wrap>
            </div>

            <img src={imgBg} alt="" className={st.plutoBg} />
            <img src={imgBgMobile} alt="" className={st.plutoMobileBg} />
        </section>
    );
};

export default Intro;
