import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { apiService } from '$shared/services/apiService';
import { PLUTO_DECIMALS } from '$shared/constants';
import { getAssetID } from '$shared/domain/constants';

import authService from '$/services/authentication/AuthenticationService';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import { getPlutoBalance } from '$/utils/balances';
import { separateThousands, toDollars } from '$/utils/formatNumber';
import { Button } from '$/components/Pluto/UI';
import { Title } from '$/components/Pluto/Layout';
import { Statistics, TokenControl } from '$/components/Pluto';

import st from './StakeModal.module.scss';

interface IProps {
    isStake?: boolean;
    stakedAmount: number;
    plutoBalance: number;
    plutoPrice: number;
    afterCall: () => void;
}

const StakeModal: FC<IProps> = ({ isStake: stake = true, stakedAmount, plutoBalance, plutoPrice, afterCall }) => {
    const { t } = useTranslation();

    const [isStake, setStake] = useState(stake);
    const [loading, setLoading] = useState(false);

    const [lpTokens, setLpTokens] = useState([
        {
            assetId: getAssetID('PLUTO'),
            name: 'PLUTO',
            description: 'PLUTO',
            quantity: 0,
            minSponsoredAssetFee: 0,
            imgSrc: apiService.getAssetImage(getAssetID('PLUTO')),
            price: plutoPrice,
            userBalancePrice: 0,
            decimals: PLUTO_DECIMALS,
            balance: 0,
        },
    ]);

    const [sendValue, setSendValue] = useState(0);

    const handleSetStake = useEffectiveCallback(() => {
        const lpTokensCopy = lpTokens;
        lpTokensCopy[0].balance = plutoBalance;
        setLpTokens(lpTokensCopy);
        setStake(true);
    });

    const handleSetUnstake = useEffectiveCallback(() => {
        const lpTokensCopy = lpTokens;
        lpTokensCopy[0].balance = stakedAmount;
        setLpTokens(lpTokensCopy);
        setStake(false);
    });

    const handleSetValues = useEffectiveCallback(() => (val) => {
        let correctedVal = val;
        if (val.length > 1 && val[1] !== '.' && val[0] === '0') {
            correctedVal = val.replace(/^0/, '');
        }
        let toSet = correctedVal;
        if (Number.isNaN(parseFloat(correctedVal))) {
            toSet = '';
        }
        setSendValue(toSet);
    });

    const stakeCall = () => {
        setLoading(true);
        authService.stakePluto(sendValue * PLUTO_DECIMALS).then(() => {
            setLoading(false);
            afterCall();
        });
    };

    const unstakeCall = () => {
        setLoading(true);
        authService.unstakePluto(sendValue * PLUTO_DECIMALS).then(() => {
            setLoading(false);
            afterCall();
        });
    };

    return (
        <div className={st.stakeModal}>
            <Title as="h3" size="h2" className={st.title}>
                {t(isStake ? 'Stake PLUTO' : 'Unstake PLUTO')}
            </Title>

            <div className={st.params}>
                <div className={st.param}>
                    <p className={st.paramTitle}>{t('Available to stake')}</p>

                    <p className={st.paramPluto}>
                        <span className={st.paramValue}>{separateThousands(getPlutoBalance(plutoBalance))}</span> PLUTO
                    </p>

                    {toDollars((getPlutoBalance(plutoBalance)) * plutoPrice)}
                </div>
                <div className={st.param}>
                    <p className={st.paramTitle}>{t('PLUTO staked')}</p>

                    <p className={st.paramPluto}>
                        <span className={st.paramValue}>{separateThousands(stakedAmount / PLUTO_DECIMALS)}</span> PLUTO
                    </p>

                    {toDollars((stakedAmount / PLUTO_DECIMALS) * plutoPrice)}
                </div>
            </div>

            <div className={st.tabs}>
                <button type="button" className={cn(st.tabBtn, { [st.active]: isStake })} onClick={handleSetStake}>
                    Stake
                </button>

                <button type="button" className={cn(st.tabBtn, { [st.active]: !isStake })} onClick={handleSetUnstake}>
                    Unstake
                </button>
            </div>

            <TokenControl
                lpTokens={lpTokens}
                maxValue={isStake ? getPlutoBalance(plutoBalance) : stakedAmount / PLUTO_DECIMALS}
                setValue={handleSetValues()}
                className={st.tokenControl}
                value={sendValue}
                withMax
            />
            <Statistics items={[{ title: 'Transaction fee', value: '0.005 WAVES' }]} className={st.statistics} />

            {isStake && !loading && (
                <Button fluid theme="orange" onClick={stakeCall}>
                    {t('Stake')}
                </Button>
            )}

            {!isStake && !loading && (
                <Button fluid theme="orange" onClick={unstakeCall}>
                    {t('Unstake')}
                </Button>
            )}

            {loading && (
                <Button fluid theme="orange" disabled>
                    {t('Sending transaction...')}
                </Button>
            )}
        </div>
    );
};

export default StakeModal;
