import { VFC, MouseEvent } from 'react';
import cn from 'classnames';

import st from './CommonToken.module.scss';
import { separateThousands, toDollars, toFixed2 } from '$/utils/formatNumber';

interface Props {
    imgSrc: string;
    name: string;
    // assetPrice: string;
    balance: number;
    decimals: number;
    price: number;
    className?: string;
    fluid?: boolean;
    userBalancePrice: number;
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const CommonToken: VFC<Props> = ({
    fluid,
    decimals,
    imgSrc,
    name,
    className,
    balance,
    price,
    userBalancePrice,
    onClick,
}) => (
    <button
        type="button"
        value={userBalancePrice}
        onClick={onClick}
        className={cn(st.container, { [st.fluid]: fluid }, className)}
    >
        <div className={st.header}>
            <img className={st.img} src={imgSrc} alt="" />

            <div className={st.left}>
                <p className={st.title}>{name}</p>

                <p className={st.text}>{toDollars(price)}</p>
            </div>
        </div>

        <div className={st.right}>
            <p className={st.title}>{separateThousands(toFixed2(balance / 10 ** decimals))}</p>

            <p className={st.text}>{toDollars(userBalancePrice)}</p>
        </div>
    </button>
);

export default CommonToken;
