import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import icoMail from '$/assets/sprite/email.svg';
import icoSeed from '$/assets/sprite/seed.svg';
import icoKeeper from '$/assets/sprite/keeper.svg';
import { Icon } from '$/components/Pluto';
import { BaseContainer, Header, Title, Wrap } from '$/components/Pluto/Layout';
import authService, { ProviderTypesEnum } from '$/services/authentication/AuthenticationService';

import st from './Auth.module.scss';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '$/constants';

const Auth: FC = () => {
    const { t } = useTranslation();
    const [leggedIn, setLoggedIn] = useState(authService.getAddress() !== undefined);

    const startAuth = async (type: ProviderTypesEnum) => {
        try {
            const authData = await authService.startAuth(type);
            console.log(authData);
            setLoggedIn(true);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={st.auth}>
            {leggedIn && <Redirect to={`${ROUTES.DASHBOARD}`} />}
            <Header className={st.header} />

            <BaseContainer>
                <Wrap>
                    <div className={st.container}>
                        <Title promo size="h3" className={st.title}>
                            {t('auth.title')}
                        </Title>

                        <button
                            type="button"
                            className={cn(st.action, st.withTag)}
                            onClick={() => startAuth(ProviderTypesEnum.Cloud)}
                        >
                            <Icon item={icoMail} className={st.icon} />

                            <Title as="span" size="h4" className={st.actionTitle}>
                                {t('auth.actions.email.title')}
                            </Title>

                            <span className={st.description}>{t('auth.actions.email.text')}</span>
                            <span className={st.tag}>{t('auth.actions.recommended')}</span>
                        </button>

                        <button type="button" className={st.action} onClick={() => startAuth(ProviderTypesEnum.Web)}>
                            <Icon item={icoSeed} className={st.icon} />

                            <Title as="span" size="h4" className={st.actionTitle}>
                                {t('auth.actions.seed.title')}
                            </Title>

                            <span className={st.description}>{t('auth.actions.seed.text')}</span>
                        </button>

                        <button type="button" className={st.action} onClick={() => startAuth(ProviderTypesEnum.Keeper)}>
                            <Icon item={icoKeeper} className={st.icon} />

                            <Title as="span" size="h4" className={st.actionTitle}>
                                {t('auth.actions.keeper.title')}
                            </Title>

                            <span className={st.description}>{t('auth.actions.keeper.text')}</span>
                        </button>
                    </div>
                </Wrap>
            </BaseContainer>
        </div>
    );
};

export default Auth;
