import { FC } from 'react';

import useWindowSize from '$/hooks/useWindowSize';
import { BuyPlutoPromo } from '$/components/Pluto';

import LPTokens from './LPTokens';
import st from './IssuePluto.module.scss';

const IssuePluto: FC = () => {
    const { isMobile } = useWindowSize();

    return (
        <div className={st.issuePluto}>
            {isMobile && <BuyPlutoPromo className={st.buyPlutoPromo} />}
            <LPTokens />
        </div>
    );
};

export default IssuePluto;
