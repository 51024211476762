import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import useOnclickOutside, { Return } from 'react-cool-onclickoutside';

import { noop } from '$shared/utils';

import { useEventListener } from './useEventListener';

type TToggleOpen = {
    isOpen: boolean;
    ref: Return;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export const useOnEscapePress = (onClose: () => void): void => {
    const handleKeyUp = useCallback(
        ({ key }: KeyboardEvent) => {
            if (key === 'Escape') {
                onClose();
            }
        },
        [onClose],
    );

    useEventListener('keyup', handleKeyUp);
};

export const useToggleOpen = (initialVisibility = false): TToggleOpen => {
    const [isOpen, setOpen] = useState(initialVisibility);

    const close = useCallback(() => setOpen(false), []);

    useOnEscapePress(isOpen ? close : noop);
    const ref = useOnclickOutside(isOpen ? close : noop);

    return { isOpen, ref, setOpen };
};
