export const toFixed =
    (fractionDigits: number) =>
    (n: number | string): string => {
        const num = typeof n === 'string' ? parseFloat(n) : n;

        return num === 0 ? '0' : num.toFixed(fractionDigits);
    };

export const toFixed8 = toFixed(8);
export const toFixed4 = toFixed(4);
export const toFixed2 = toFixed(2);

export const omitZeros = (n: number): string => (n === 0 ? '0' : n.toFixed(2).replace(/0+$/, ''));

export const separateThousands = (number: number | string = 0): string =>
    new Intl.NumberFormat('en-US', { maximumSignificantDigits: 7 }).format(Number(number));

export const toDollars = (number = 0, maximumFractionDigits = 2): string =>
    `$ ${new Intl.NumberFormat('en', {
        currency: 'USD',
        maximumFractionDigits,
    }).format(Number.isNaN(number) ? 0 : number)}`;

export const toPercentage = (number = 0, maximumFractionDigits = 3): string => {
    return new Intl.NumberFormat('en', {
        maximumFractionDigits,
    }).format(Number.isNaN(number) ? 0 : number);
};

export const toPercent = (number: string | number = 0): string => {
    if (typeof number === 'string') {
        return `${number}%`;
    }

    return new Intl.NumberFormat('en', {
        style: 'percent',
        maximumFractionDigits: 2,
    }).format(number / 100);
};
