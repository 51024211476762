import React from 'react';

import { Unavailable } from '$/components/Pluto';

type ApiErrorBoundaryState = {
    hasError: boolean;
};

class ApiErrorBoundary extends React.Component<unknown, ApiErrorBoundaryState> {
    static getDerivedStateFromError(_error: unknown): ApiErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    state: ApiErrorBoundaryState = { hasError: false };

    render() {
        if (this.state.hasError) {
            // TODO: decide later if we want retry the call
            return <Unavailable />;
        }

        return this.props.children;
    }
}

export default ApiErrorBoundary;
