import { FC, ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import st from './Wrap.module.scss';

interface IProps {
    className?: string;
    children?: ReactNode;
}

const Wrap: FC<IProps> = ({ className, children }) => {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [pathname]);

    return <div className={cn(st.wrap, className)}>{children}</div>;
};

export default Wrap;
