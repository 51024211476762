import { VFC } from 'react';
import cn from 'classnames';

import st from './Token.module.scss';
import { IAssetBlockchainDetails } from '$shared/services/types';

interface Props {
    token: IAssetBlockchainDetails;
    className?: string;
}

const Token: VFC<Props> = ({ token, className }) => (
    <span className={cn(st.token, className)}>
        <img src={token.imgSrc} alt="" className={st.logo} />
        {token.name}
    </span>
);

export default Token;
