import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import { RoundedCard } from '$/components/Pluto';
import { Title, Wrap } from '$/components/Pluto/Layout';

import st from './ReserveCurrency.module.scss';

interface IProps {
    className?: string;
    id?: string;
}

const ReserveCurrency: FC<IProps> = ({ className, id }) => {
    const { t } = useTranslation();

    return (
        <section className={cn(st.reserveCurrency, className)} id={id}>
            <Wrap>
                <Title promo as="h2" className={st.mainTitle}>
                    <Trans i18nKey="home.reserve_currency.title" components={{ br: <br /> }} />
                </Title>

                <ul className={st.list}>
                    {[...Array(3)].map((_, index) => (
                        <RoundedCard as="li" key={String(index)} className={st.item}>
                            <span className={st.text}>{t(`home.reserve_currency.text_${index + 1}`)}</span>
                        </RoundedCard>
                    ))}
                </ul>

                <div className={st.meta}>
                    <Trans i18nKey="home.reserve_currency.meta" />
                </div>
            </Wrap>
        </section>
    );
};

export default ReserveCurrency;
