import { useQuery, UseQueryResult } from 'react-query';

import { getAssetID } from '$shared/domain/constants';
import { apiService } from '$shared/services/apiService';

import authService from '$/services/authentication/AuthenticationService';

interface IAssetBalance {
    assetId: string;
    balance: number;
}

export const usePlutoBalance = (): UseQueryResult<IAssetBalance> =>
    useQuery(['pluto-balance'], () => authService.getAssetBalance(getAssetID('PLUTO')));

export const useStakedBalance = (): UseQueryResult<number> => {
    const address = authService.getAddress();
    return useQuery(['staked-balance', address], () => apiService.getStakedBalance(address));
};
