import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Title } from '$/components/Pluto/Layout';

import st from './ErrorMessage.module.scss';

interface IProps {
    img?: string;
    className?: string;
    title?: string;
    text?: ReactNode | string;
}

const ErrorMessage: FC<IProps> = ({ className, children, img, title, text }) => (
    <div className={cn(st.errorMessage, className)}>
        <img src={img} alt="" className={st.img} />

        {title && (
            <Title as="h3" size="h3" className={st.title}>
                {title}
            </Title>
        )}

        <Title as="div" size="h4" className={st.text}>
            {typeof text === 'string' ? <p>{text}</p> : text}
        </Title>

        {children}
    </div>
);

export default ErrorMessage;
