import { getAddress, getAssetID } from '$shared/domain/constants';

import {
    amount,
    assetId,
    IAssetBalance,
    IAssetBlockchainDetails,
    IBlockHeader,
    IEvalResult,
    IEvaluateIssuePlutoResult,
    IEvaluateOnboardingsResult,
    ILandingDetails,
    IMarketPriceEvalResult,
    IOnboarding,
    IOnboardingParametersEvalResult,
    IPlutoOnboardingParameters,
    IPlutoTreasuryParameters,
    IWavesCapResponse,
    IWavesCapResponseData,
    IWithAsset,
    IWithPrice,
} from './types';
import { PLUTO_DECIMALS, PLUTO_DECIMALS_NUMBER, USDN_DECIMALS } from '$shared/constants';
import axios from 'axios';
// import * as rax from 'retry-axios';

const mAxs = axios.create();
// mAxs.defaults.raxConfig = {
//     instance: mAxs,
//     retry: 3,
//     noResponseRetries: 2,
//     retryDelay: 1000,
//     statusCodesToRetry: [
//         [100, 199],
//         [400, 429],
//         [500, 599],
//     ],
//     backoffType: 'exponential',
//     onRetryAttempt: (err) => {
//         const cfg = rax.getConfig(err);
//         // @ts-ignore
//         console.log(`Retry attempt #${cfg.currentRetryAttempt}`);
//     },
// };
// const interceptorId = rax.attach(mAxs);

export const MS_IN_DAY = 86400000;
export const NODE_URL = process.env.NODE_URL || 'https://nodes.wavesnodes.com';

class ApiService {
    cachedBlockHeaders: Map<number, IBlockHeader> = new Map<number, IBlockHeader>();

    async getPortfolio(address: string) {
        const assetsForBalance = [
            ...ApiService.getLpTokenIds(),
            getAssetID('PLUTO'),
            // getAssetID('PARENT_POOL_LP'),
            // getAssetID('USDN')
        ];
        const formData = assetsForBalance.reduce((acc: FormData, assetId) => {
            if (assetId === getAssetID('WAVES')) return acc;
            acc.append('id', assetId as string);
            return acc;
        }, new FormData());

        const { data } = await mAxs.post(`${NODE_URL}/assets/balance/${address}`, formData);
        const balances = data.balances;
        balances.unshift({
            assetId: getAssetID('WAVES'),
            balance: await this.getWavesBalance(address),
        });
        return balances;
    }

    async evaluateScript(address: string, expr: string): Promise<IEvalResult> {
        const { data } = await mAxs.post(`${NODE_URL}/utils/script/evaluate/${address}`, {
            expr,
        });
        return data;
    }

    async getPlutoMarketValue() {
        const evalValue: IMarketPriceEvalResult = await this.evaluateScript(
            getAddress('PLUTO_DAPP'),
            `getMarketValue(false)`,
        );
        return evalValue?.result?.value['_2'].value || 0;
    }

    async getWavesBalance(address: string) {
        const { data } = await mAxs.get(`${NODE_URL}/addresses/balance/details/${address}`);
        return data.available;
    }

    async getWavesPrice() {
        const { data } = await mAxs.get(`${NODE_URL}/addresses/data/3P5Bfd58PPfNvBM2Hy8QfbcDqMeNtzg7KfP/price`);
        return data.value / 1e6;
    }

    async getPlutoTreasuryValue() {
        const evalValue: IMarketPriceEvalResult = await this.evaluateScript(
            getAddress('PLUTO_DAPP'),
            `getTreasuryValue(false)`,
        );

        return evalValue?.result?.value['_2'].value || 0;
    }

    async getPlutoQuantity() {
        const evalValue: IMarketPriceEvalResult = await this.evaluateScript(
            getAddress('PLUTO_DAPP'),
            `getPlutoQuantity(false)`,
        );

        return evalValue?.result?.value['_2'].value || 0;
    }

    async getPlutoTreasuryParameters() {
        const evalValue: IPlutoTreasuryParameters = await this.evaluateScript(
            getAddress('PLUTO_DAPP'),
            `getTreasuryParameters(false)`,
        );
        return (
            evalValue?.result?.value['_2'].value.split(',').map((number) => {
                const intValue = parseInt(number);
                return !Number.isNaN(intValue) ? intValue : 0;
            }) || [0, 0, 0]
        );
    }

    async getStakingBlockEmissionValue() {
        const evalValue: IMarketPriceEvalResult = await this.evaluateScript(
            getAddress('PLUTO_DAPP'),
            `getStakingBlockEmission(false)`,
        );
        return evalValue?.result?.value['_2'].value || 0;
    }

    async getPlutoMarketPrice() {
        const evalValue: IMarketPriceEvalResult = await this.evaluateScript(
            getAddress('PLUTO_DAPP'),
            `getMarketPrice(false)`,
        );
        return evalValue?.result?.value['_2'].value || 0;
    }

    async getPlutoOnboardingParameters(): Promise<IPlutoOnboardingParameters> {
        const evalValue: IOnboardingParametersEvalResult = await this.evaluateScript(
            getAddress('PLUTO_DAPP'),
            `getOnboardingParameters(false)`,
        );
        const premium = evalValue?.result?.value['_2'].value[1].value || 0;
        const period = evalValue?.result?.value['_2'].value[2].value || 0;
        return {
            growthFactor: evalValue?.result?.value['_2'].value[0].value,
            premium,
            apy: ApiService.getApyFromPremium(premium, period),
            period,
            teamAllocation: evalValue?.result?.value['_2']?.value[3].value,
            buybackRatio: evalValue?.result?.value['_2']?.value[4].value,
        };
    }

    static getLpTokens() {
        return {
            WAVES: getAssetID('WAVES'),
            USDC: getAssetID('USDC'),
            USDT: getAssetID('USDT'),
            USDN: getAssetID('USDN'),
        };
    }

    static getLpTokenIds() {
        return Object.values(ApiService.getLpTokens());
    }

    async getParentPoolLpTokenPrice() {
        const { data } = await mAxs.get('https://pluto-back.herokuapp.com/treasury');
        return data.parent_lp_price;
    }

    async getLpTokensMarketPrice() {
        const puzzlePoolAddress = getAddress('PUZZLE_VIRES_LP_TOKENS_POOL');
        const usdnAssetId = getAssetID('USDN');

        const { data: staticState } = await mAxs.get(
            `${NODE_URL}/addresses/data/${puzzlePoolAddress}?matches=static_.*?`,
        );

        const balances = await this.getPortfolio(puzzlePoolAddress);
        const balancesMap = new Map<assetId, amount>();

        balances.reduce((acc, balance: IAssetBalance) => {
            acc.set(balance.assetId, balance.balance);
            return acc;
        }, balancesMap);

        const usdnBalance = balancesMap.get(usdnAssetId) || 0;

        const parentPoolLpPrice = await this.getParentPoolLpTokenPrice();
        const plutoPriceDetails = await this.getPlutoPriceDetails();
        const wavesPrice = await this.getWavesPrice();

        const result = staticState
            .filter((kv) => kv.key.indexOf('static_') !== -1 && kv.key.indexOf('_weight') !== -1)
            .map((kv) => {
                const assetId = kv.key.split('_')[1];
                const assetBalance = balancesMap.get(assetId) || 0;
                const usdnWeight = staticState.find((kv) => kv.key === `static_${usdnAssetId}_weight`).value;
                const decimalsValue = staticState.find((kv) => kv.key === `static_${assetId}_decimals`).value;
                const assetWeight = kv.value;
                let price =
                    ((usdnBalance * assetWeight * PLUTO_DECIMALS) / (assetBalance * usdnWeight) / USDN_DECIMALS) *
                    (10 ** decimalsValue / 1e6);
                if (assetId === getAssetID('WAVES')) {
                    price = wavesPrice;
                } else if (assetId === getAssetID('PLUTO')) {
                    price = plutoPriceDetails.lastPrice * 1e2;
                } else if (
                    assetId === getAssetID('USDT') ||
                    assetId === getAssetID('USDC') ||
                    assetId === getAssetID('USDN')
                ) {
                    price = 1e2;
                }
                return {
                    weight: kv.value,
                    decimals: decimalsValue,
                    assetId,
                    price: price === Infinity ? 0 : price,
                };
            });
        result.push({
            weight: 0,
            decimals: PLUTO_DECIMALS_NUMBER,
            assetId: getAssetID('PARENT_POOL_LP'),
            price: parentPoolLpPrice * 1e2,
        });
        result.unshift({
            weight: 0,
            decimals: PLUTO_DECIMALS_NUMBER,
            assetId: getAssetID('WAVES'),
            price: wavesPrice * 1e2,
        });
        return result;
    }

    async getTokenDetailsMap(ids: string[]) {
        const { data } = await mAxs.post(`${NODE_URL}/assets/details`, {
            ids,
        });
        return [
            {
                assetId: 'WAVES',
                name: 'Waves',
                description: 'Waves',
                decimals: 8,
                quantity: 100 * 1e8,
                minSponsoredAssetFee: 0,
                imgSrc: '',
            },
            ...data,
        ].reduce((acc, assetFullDetails: IAssetBlockchainDetails) => {
            acc.set(assetFullDetails.assetId, assetFullDetails);
            return acc;
        }, new Map<assetId, IAssetBlockchainDetails>());
    }

    async getUserLpTokens(
        address: string | undefined,
    ): Promise<Array<IAssetBalance & IWithPrice & IAssetBlockchainDetails>> {
        if (!address) return Promise.resolve([]);
        const userLpTokens = await this.getPortfolio(address);
        const lpTokensMarketPrice = await this.getLpTokensMarketPrice();
        const tokenDetails = await this.getTokenDetailsMap(userLpTokens.map((token) => token.assetId));
        const lpTokensMarketPriceMap = lpTokensMarketPrice.reduce((acc, asset) => {
            acc.set(asset.assetId, {
                price: asset.price,
                decimals: asset.decimals,
            });
            return acc;
        }, new Map<assetId, { price: number; decimals: number }>());

        return userLpTokens.map((assetDetails) => {
            const assetParams = lpTokensMarketPriceMap.get(assetDetails.assetId) || {
                price: 0,
                decimals: 0,
            };
            const pricePerToken = assetParams.price / 100;

            return {
                ...assetDetails,
                decimals: assetParams.decimals,
                ...tokenDetails.get(assetDetails.assetId),
                price: pricePerToken,
                imgSrc: this.getAssetImage(assetDetails.assetId),
                userBalancePrice: (assetDetails.balance / 10 ** assetParams.decimals) * pricePerToken,
            };
        });
    }

    getPotentialPluto(assetId: assetId, amount: number): Promise<IEvaluateIssuePlutoResult> {
        return this.evaluateScript(getAddress('PLUTO_MINT_DAPP'), `evaluateIssuePluto(false, "${assetId}", ${amount})`);
    }

    async getBlockHeader(height: number) {
        if (!this.cachedBlockHeaders.has(height)) {
            const { data: header } = await mAxs.get(`${NODE_URL}/blocks/headers/at/${height}`);
            this.cachedBlockHeaders.set(height, header);
        }
        return this.cachedBlockHeaders.get(height);
    }

    async getOnboardings(address: string): Promise<Array<IOnboarding & IWithAsset>> {
        const evaluated: IEvaluateOnboardingsResult = await this.evaluateScript(
            getAddress('PLUTO_STAKE_DAPP'),
            `getOnboardings(false, "${address}")`,
        );
        const resultString = evaluated.result.value['_2'].value;
        const onboardingsString = resultString.trim().replace(/,$/gm, '');
        let onboardings: IOnboarding[] = [];
        try {
            onboardings = JSON.parse(`[${onboardingsString}]`);
        } catch (e) {
            console.error(onboardingsString, e);
        }
        const assetDetails = await this.getTokenDetailsMap(onboardings.map((onboarding) => onboarding.assetId));
        const result: Array<IOnboarding & IWithAsset> = [];
        const plutoPrice = await this.getPlutoMarketPrice();
        for (const onboardingDetails of onboardings) {
            const startDateBlockHeader = await this.getBlockHeader(onboardingDetails.startHeight);
            const startDateTimestamp = startDateBlockHeader ? startDateBlockHeader.timestamp : Date.now();
            const startDate = new Date(startDateTimestamp);
            const daysNumber = (onboardingDetails.finishHeight - onboardingDetails.startHeight) / 1440;
            const endDateTimestamp = startDateTimestamp + daysNumber * MS_IN_DAY;

            const daysPassed = Math.trunc((Date.now() - startDateTimestamp) / MS_IN_DAY);

            const endDate = new Date(endDateTimestamp);

            result.push({
                ...onboardingDetails,
                asset: {
                    ...assetDetails.get(onboardingDetails.assetId),
                    imgSrc: this.getAssetImage(onboardingDetails.assetId),
                },
                apy: ApiService.getApyFromPremium(onboardingDetails.premium, daysNumber),
                daysPassed,
                daysNumber,
                startDate,
                endDate,
                plutoPrice,
            });
        }
        return result.reverse();
    }

    getAssetImage(assetId) {
        if (assetId === getAssetID('PLUTO')) {
            return '/assets/img/pluto-logo.png';
        }

        if (assetId === getAssetID('PARENT_POOL_LP')) {
            return '/assets/img/pluto-lp-image.png';
        }
        return `https://waves.exchange/static/icons/assets/${assetId}.svg`;
    }

    async getCurrentHeight() {
        const { data } = await mAxs.get(`${NODE_URL}/blocks/height`);
        return data.height;
    }

    private static getApyFromPremium(premium: number, period: any) {
        return Math.floor((1 + premium / 1e4) ** (365 / period) * 100) - 100;
    }

    async getLandingParameters(): Promise<ILandingDetails> {
        const treasuryParameters = await this.getPlutoTreasuryParameters();
        return {
            treasuryValue: treasuryParameters[0],
            marketValue: treasuryParameters[1],
            stakingApy: treasuryParameters[2],
            date: new Date(),
        };
    }

    async getPlutoPriceDetails(): Promise<IWavesCapResponseData> {
        const { data } = await mAxs.get<IWavesCapResponse[]>(
            `https://wavescap.com/api/markets/Ajso6nTTjptu2UHLx6hfSXVtHFtRBJCkKYd5SAyj7zf5.json`,
        );
        return data.filter(
            (item) => item.amount_asset_id === getAssetID('PLUTO') && item.price_asset_id === getAssetID('USDN'),
        )[0].data;
    }

    async getStakedBalance(address: string | undefined) {
        if (!address) return 0;
        const evalValue: IMarketPriceEvalResult = await this.evaluateScript(
            getAddress('PLUTO_STAKE_DAPP'),
            `getStakedAmount(false, "${address}")`,
        );
        return evalValue?.result?.value['_2'].value || 0;
    }
}

export const apiService = new ApiService();
