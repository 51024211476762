import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentHeight } from '$/hooks/lpTokens';
import { useOnboardindsList, usePlutoOnboardingParameters } from '$/hooks/onboarding';
import { INITIAL_ONBOARDING_PARAMETERS } from '$/constants/onboarding';
import { Tooltip } from '$/components/Pluto/UI';
import { Title } from '$/components/Pluto/Layout';

import EmptyList from './EmptyList';
import IssuedPlutoItem from './IssuedPlutoItem';
import st from './IssuedPluto.module.scss';

const IssuedPluto: FC = () => {
    const { t } = useTranslation();

    const { data: onboardingParameters = INITIAL_ONBOARDING_PARAMETERS } = usePlutoOnboardingParameters();
    const { data: onboardindsList = [] } = useOnboardindsList();
    const { data: currentHeight, refetch } = useCurrentHeight();

    useEffect(() => {
        const interval = setInterval(() => refetch, 30_000);

        return () => {
            clearInterval(interval);
        };
    }, [refetch]);

    if (!onboardindsList) {
        return <EmptyList />;
    }

    return (
        <>
            <div className={st.header}>
                <Title as="h2" size="h2" className={st.title}>
                    {t('issued-pluto.title')}
                </Title>

                {/*
                <Tooltip position="right" target={<Icon size="small" item={icoInfo} className={st.icon} />}>
                    {t('ipsum ipsum ipsum ipsum ipsum ipsum ipsum ipsum ipsum ipsum ipsum ipsum')}
                </Tooltip>
                */}

                <Tooltip
                    position="bottom"
                    target={
                        <p className={st.lock}>{t(`Current lock-up period ${onboardingParameters.period} days`)}</p>
                    }
                >
                    {t(
                        'When you deposit LP tokens, your PLUTO is instantly issued and staked on your account with a lock-up period. After the lock-up period is over, your PLUTO appears in staking.',
                    )}
                </Tooltip>

                {/*
                <div className={st.dropDownContainer}>
                    <Dropdown
                        className={st.dropDown}
                        data={['Status']}
                        defaultValue={['Status']}
                        filter={false}
                    />
                </div>
                */}
            </div>

            <div className={st.items}>
                {onboardindsList.map((onboarding) => {
                    const onboardingData = { ...onboarding, currentHeight };
                    return <IssuedPlutoItem key={String(onboarding.globalNum)} {...onboardingData} />;
                })}
            </div>
        </>
    );
};

export default IssuedPluto;
