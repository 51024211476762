import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Button } from '$/components/Pluto/UI';
import { Title } from '$/components/Pluto/Layout';

import imgRocket from './img/rocket.png';
import st from './IssuePluto.module.scss';

interface IProps {
    className?: string;
    onClick: () => void;
}

const IssuePluto: FC<IProps> = ({ className, onClick }) => {
    const { t } = useTranslation();

    return (
        <div className={cn(st.issuePluto, className)}>
            <img src={imgRocket} alt="" className={st.img} />

            <Title as="h3" size="h2" className={st.title}>
                {t('Issue PLUTO')}
            </Title>

            <p className={st.text}>
                {t(
                    'Issue PLUTO for LP tokens with instant staking and get exclusive APY during the Onboarding period or buy PLUTO directly from a pool',
                )}
            </p>

            <Button fluid theme="orange" className={st.btn} onClick={onClick}>
                {t('Get PLUTO')}
            </Button>
        </div>
    );
};

export default IssuePluto;
