import { FC } from 'react';
import cn from 'classnames';

import st from './Layout.module.scss';

interface IProps {
    className?: string;
}

const Layout: FC<IProps> = ({ className, children }) => <div className={cn(st.container, className)}>{children}</div>;

export default Layout;
