import { FC, MouseEvent } from 'react';
import cn from 'classnames';

import CommonToken from '$/components/Pluto/CommonToken';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';

import st from './ChooseToken.module.scss';
import { IAssetFullDetails } from '$shared/services/types';

interface Props {
    className?: string;
    onClose: () => void;
    lpTokens: IAssetFullDetails[];
    onChoose: (e: MouseEvent<HTMLButtonElement>, token: IAssetFullDetails) => void;
}

const ChooseToken: FC<Props> = ({ className, lpTokens, onClose, onChoose }) => {
    // const { t } = useTranslation();

    // const [value, setValue] = useState('');

    const handleChoose = useEffectiveCallback((e: MouseEvent<HTMLButtonElement>, token: IAssetFullDetails) => {
        onChoose(e, token);
        onClose();
    });

    return (
        <div className={cn(st.container, className)}>
            {/* <header className={st.header}> */}
            {/*    <Search */}
            {/*        fluid */}
            {/*        id="token-search" */}
            {/*        value={value} */}
            {/*        placeholder={t('Search by LP tokens or paste address')} */}
            {/*        onChange={(e) => setValue(e.target.value)} */}
            {/*    /> */}

            {/*    <Button size="small" theme="primary" className={st.cancelBtn} onClick={onClose}> */}
            {/*        {t('Cancel')} */}
            {/*    </Button> */}
            {/* </header> */}

            {/* <div className={st.trending}> */}
            {/*    <p className={st.trendingTitle}>{t('Trending LP tokens')}</p> */}

            {/*    <div className={st.trendingList}> */}
            {/*        {TRENDING_TOKENS.map((token) => ( */}
            {/*            <TrandingToken key={token.title} onClick={handleChoose} {...token} /> */}
            {/*        ))} */}
            {/*    </div> */}
            {/* </div> */}

            <div className={st.commonList}>
                {lpTokens.map((token) => (
                    <CommonToken
                        fluid
                        // eslint-disable-next-line react/no-array-index-key
                        key={token.assetId}
                        className={st.commonToken}
                        onClick={(e) => handleChoose(e, token)}
                        {...token}
                    />
                ))}
            </div>
        </div>
    );
};

export default ChooseToken;
