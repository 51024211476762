import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import history from '$/services/history';
import queryClient from '$/services/query-client';
import AppProvider from '$/providers';
import { ROUTES } from '$/constants';
import { Auth, Home, PersonalCabinet } from '$/pages';

import './styles/styles.scss';

const App = (): JSX.Element => {
    // specify base href from env variable 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    const basename = process.env.NODE_ENV === 'development' ? '/' : PUBLIC_URL || '/';

    return (
        <AppProvider queryClient={queryClient}>
            {/* @ts-expect-error don't know why it's here */}
            <Router history={history} basename={basename}>
                <Switch>
                    <Route exact path={ROUTES.HOME} component={Home} />
                    <Route exact path={ROUTES.AUTH} component={Auth} />
                    <Route path={ROUTES.DASHBOARD} component={PersonalCabinet} />
                </Switch>
            </Router>
        </AppProvider>
    );
};

export default App;
