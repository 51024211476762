import React, { ReactNode } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';

interface Props {
    queryClient: QueryClient;
    children: ReactNode;
}

const ReactQueryProvider = ({ queryClient, children }: Props): JSX.Element => (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export default ReactQueryProvider;
