import React, { FC, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { TSVG } from '$/types/svg';
import { Icon } from '$/components/Pluto';

import st from './Button.module.scss';

export interface Props {
    disabled?: boolean;
    external?: boolean;
    fluid?: boolean;
    className?: string;
    href?: string;
    icon?: TSVG;
    iconClassName?: string;
    theme?: 'primary' | 'secondary' | 'orange' | 'gray' | 'green';
    size?: 'default' | 'x-small' | 'small' | 'medium';
    onClick?: (event: SyntheticEvent) => void;
}

const Button: FC<Props> = (props) => {
    const {
        disabled,
        external,
        fluid,
        children,
        className,
        href,
        icon,
        iconClassName,
        theme = 'primary',
        size = 'default',
        onClick,
    } = props;

    const mainClasses = cn(
        st.btn,
        st[theme],
        st[size],
        {
            [st.fluid]: fluid,
            [st.disabled]: disabled,
        },
        className,
    );

    const content = (
        <>
            {children}
            {icon && <Icon item={icon} className={cn(st.icon, iconClassName)} />}
        </>
    );

    if (external) {
        return (
            <a className={mainClasses} href={href} rel="noopener noreferrer" target="_blank" onClick={onClick}>
                {content}
            </a>
        );
    }

    if (href) {
        return (
            <Link className={mainClasses} to={href} onClick={onClick}>
                {content}
            </Link>
        );
    }

    return (
        <button type="button" disabled={disabled} className={mainClasses} onClick={onClick}>
            {content}
        </button>
    );
};

export default Button;
