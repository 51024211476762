export const PERSONAL_CABINET = {
    ISSUE_PLUTO: 'issue',
    ISSUED_PLUTO: 'issued',
};

const ROUTES = {
    HOME: '/',
    AUTH: '/auth',

    DASHBOARD: '/dashboard',
    DASHBOARD_INNER: '/dashboard/:tab',
};

export default ROUTES;
