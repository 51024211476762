import React from 'react';
import ReactDOM from 'react-dom';
import * as dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import './i18n';
import App from './App';

declare global {
    interface Window {
        timeStart: any;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

dayjs.extend(LocalizedFormat);
dayjs.extend(duration);

ReactDOM.render(<App />, document.getElementById('app'));
