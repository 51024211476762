import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import cn from 'classnames';

import { ROUTES } from '$/constants';

import imgLogo from './img/logo.png';
import imgLogoDark from './img/logo-dark.png';
import st from './Logo.module.scss';

interface IProps {
    dark?: boolean;
    className?: string;
}

const Logo: FC<IProps> = ({ className, dark }) => {
    const { pathname } = useLocation();

    const isLink = pathname !== ROUTES.HOME;
    const logo = <img src={dark ? imgLogoDark : imgLogo} alt="Pluto" className={cn(st.logo, className)} />;

    if (isLink) {
        return <Link to={ROUTES.HOME}>{logo}</Link>;
    }

    return logo;
};

export default Logo;
