import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { DummyToken, IAssetFullDetails } from '$shared/services/types';

import icoInfo from '$/assets/sprite/info.svg';
import icoWallet from '$/assets/sprite/wallet.svg';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import { separateThousands, toDollars, toFixed2 } from '$/utils/formatNumber';
import { Button, Tag, Tooltip } from '$/components/Pluto/UI';
import { Title } from '$/components/Pluto/Layout';

import ChooseTokenButton from '../ChooseTokenButton';
import Token from '../Token';
import Icon from '../Icon';
import st from './TokenControl.module.scss';

interface IProps {
    apy?: number;
    period?: number;
    premium?: number;
    balance?: number;
    maxValue?: number;
    className?: string;
    setValue: (num: number) => void;
    value: number;
    withChooseToken?: boolean;
    withMax?: boolean;
    readOnly?: boolean;
    lpTokens: IAssetFullDetails[];
    defaultSelectedToken?: IAssetFullDetails;
    onTokenClick?: () => void;
    setToken?: (token: IAssetFullDetails) => void;
}

const TokenControl: FC<IProps> = ({
    apy,
    period,
    premium,
    balance,
    lpTokens,
    className,
    value,
    setValue,
    maxValue,
    readOnly,
    withChooseToken,
    withMax,
    defaultSelectedToken,
    onTokenClick,
    setToken,
}) => {
    const { t } = useTranslation();

    const defaultValue = lpTokens && lpTokens.length > 0 ? lpTokens[0] : DummyToken;
    const [currentToken, setCurrentToken] = useState(defaultValue);
    const [inputValue, setInputValue] = useState(readOnly ? Number(value).toFixed(currentToken.decimals) : value);
    const [isFocused, setFocused] = useState(false);

    useEffect(() => {
        setCurrentToken(lpTokens && lpTokens.length > 0 ? lpTokens[0] : DummyToken);
    }, [lpTokens]);

    useEffect(() => {
        if (defaultSelectedToken) {
            setCurrentToken(defaultSelectedToken);
        }
    }, [defaultSelectedToken]);

    const handleChange = useEffectiveCallback(({ currentTarget: { value: newValue } }) => {
        const matches = newValue.match(/\.+/g) || [];

        if (matches.length <= 1) {
            const val = newValue.replace(/[^\d.]/g, '');
            setValue(val);
            setInputValue(val);
        }
    });

    const handleChooseToken = useEffectiveCallback((_e, token) => {
        setCurrentToken(token as IAssetFullDetails);

        const maxAvailable = token.balance / 10 ** token.decimals;

        if (maxAvailable < Number(inputValue)) {
            setValue(maxAvailable);
            setInputValue(maxAvailable.toString());
        }

        if (setToken) {
            setToken(token);
        }
    });

    const handleToggleFocus = useEffectiveCallback(() => setFocused(!isFocused));

    return (
        <div className={cn(st.control, { [st.focused]: isFocused }, className)}>
            <div className={st.controlHeader}>
                {withChooseToken && (
                    <ChooseTokenButton
                        className={st.dropdown}
                        lpTokens={lpTokens}
                        buttonClassName={st.controlTitle}
                        currentOption={currentToken}
                        onChoose={handleChooseToken}
                    />
                )}

                {!withChooseToken && (
                    <button type="button" className={cn(st.tokenBtn, st.controlTitle)} onClick={onTokenClick}>
                        <Token token={currentToken} />
                    </button>
                )}

                {withMax && (
                    <Button
                        size="small"
                        onClick={() => setValue(maxValue || currentToken.balance / 10 ** currentToken.decimals)}
                    >
                        {t('MAX')}
                    </Button>
                )}

                {apy && (
                    <Tag className={st.tag}>
                        {t('Onboarding APY')} {`${separateThousands(apy)}%`}
                        <Tooltip
                            containerClassName={st.tooltip}
                            position="top"
                            target={<Icon size="x-small" item={icoInfo} />}
                        >
                            <p>
                                {`ROI in ${period} days:`}
                                &nbsp;<b>{`${premium ? premium / 100 : 0} %`}</b>
                            </p>
                        </Tooltip>
                    </Tag>
                )}
            </div>

            <div className={st.controlFooter}>
                <div className={st.field}>
                    <Title as="p" size="h2" className={st.pseudoValueContainer}>
                        <span className={st.pseudoValue}>{!value ? 0 : value}</span>

                        <input
                            type="number"
                            value={value}
                            readOnly={readOnly}
                            className={cn(st.input, { [st.gray]: !value })}
                            onFocus={handleToggleFocus}
                            onBlur={handleToggleFocus}
                            onChange={handleChange}
                        />
                    </Title>
                </div>

                {balance !== undefined && (
                    <div className={st.balance}>
                        <Icon item={icoWallet} className={st.balanceIcon} />
                        <p>
                            {t('Balance')}:&nbsp;{toFixed2(currentToken.balance / 10 ** currentToken.decimals)}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TokenControl;
