import { FC } from 'react';
import cn from 'classnames';

import st from './Sidebar.module.scss';

interface IProps {
    className?: string;
}

const Sidebar: FC<IProps> = ({ className, children }) => (
    <aside className={cn(st.sidebar, className)}>{children}</aside>
);

export default Sidebar;
