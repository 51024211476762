import ETokens from './types';

// eslint-disable-next-line import/prefer-default-export
export const TABS = [
    {
        title: 'All',
        slug: ETokens.ALL,
    },
    {
        title: 'PuzzleSwap',
        slug: ETokens.PUZZLE_SWAP,
    },
    {
        title: 'WavesExchange',
        slug: ETokens.WAVES_EXCHANGE,
    },
    {
        title: 'Swop.fi',
        slug: ETokens.SWOP_FI,
    },
    {
        title: 'Vires',
        slug: ETokens.VIRES,
    },
];
