import { useCallback, useEffect, useRef } from 'react';

/**
 * Works just like useCallback, but without need of passing deps array
 * @param handler
 */
const useEffectiveCallback = <Args extends any[], R>(handler: (...args: Args) => R): ((...args: Args) => R) => {
    const savedHandler = useRef(handler);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    return useCallback((...args: Args) => savedHandler.current(...args), [savedHandler]);
};

export default useEffectiveCallback;
