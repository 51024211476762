import { FC } from 'react';

import icoBurger from '$/assets/sprite/burger.svg';
import icoWallet from '$/assets/sprite/wallet.svg';
import { useToggleOpen } from '$/hooks/useToggleOpen';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import { Logo } from '$/components/Pluto/Layout';
import { Icon } from '$/components/Pluto';

import MobileSidebar from '../MobileSidebar';
import MobileWalletInfo from '../MobileWalletInfo';
import st from './MobileHeader.module.scss';

const MobileHeader: FC = () => {
    const { isOpen: isSidebarOpen, ref: sidebarRef, setOpen: setSidebarOpen } = useToggleOpen();
    const { isOpen: isWalletInfoOpen, ref: walletInfoRef, setOpen: setWalletInfoOpen } = useToggleOpen();

    const handleSidebarOpen = useEffectiveCallback(() => setSidebarOpen(true));

    const handleWalletInfoOpen = useEffectiveCallback(() => setWalletInfoOpen(true));
    const handleWalletInfoClose = useEffectiveCallback(() => setWalletInfoOpen(false));

    return (
        <>
            <div className={st.mobileHeader}>
                <button type="button" className={st.actionBtn} onClick={handleSidebarOpen}>
                    <Icon item={icoBurger} />
                </button>

                <Logo dark className={st.logo} />

                <button type="button" className={st.actionBtn} onClick={handleWalletInfoOpen}>
                    <Icon item={icoWallet} />
                </button>
            </div>

            {isSidebarOpen && <MobileSidebar ref={sidebarRef} />}

            {isWalletInfoOpen && <MobileWalletInfo ref={walletInfoRef} onClose={handleWalletInfoClose} />}
        </>
    );
};

export default MobileHeader;
