import type { RecordByEnv } from '../types';

export type Addresses = {
    PLUTO_DAPP: string;
    PLUTO_MINT_DAPP: string;
    PLUTO_STAKE_DAPP: string;
    PUZZLE_VIRES_LP_TOKENS_POOL: string;
};

const ADDRESS: RecordByEnv<Addresses> = {
    DEVELOPMENT: {
        PLUTO_DAPP: '3P3a1jssxc2CGdyC7SGbTJc9ZvTjZ5jBMLZ',
        PLUTO_MINT_DAPP: '3PPKvu6x52MFaMGfw3i2byPVXXBuH7FTNMU',
        PLUTO_STAKE_DAPP: '3P7dGTVZp8VLDYy3XEaUQbiqfi9cMK1Ly5q',
        PUZZLE_VIRES_LP_TOKENS_POOL: '3P93Kqz8PUbCudvMyjbci54WxQrtBtw4dbZ',
    },
    PRODUCTION: {
        PLUTO_DAPP: '3P3a1jssxc2CGdyC7SGbTJc9ZvTjZ5jBMLZ',
        PLUTO_MINT_DAPP: '3PPKvu6x52MFaMGfw3i2byPVXXBuH7FTNMU',
        PLUTO_STAKE_DAPP: '3P7dGTVZp8VLDYy3XEaUQbiqfi9cMK1Ly5q',
        PUZZLE_VIRES_LP_TOKENS_POOL: '3P93Kqz8PUbCudvMyjbci54WxQrtBtw4dbZ',
    },
    TEST: {
        PLUTO_DAPP: '',
        PLUTO_MINT_DAPP: '3P9VVzzkP1Cfsk3LtTeuUaQqUt5D7sLthLe',
        PLUTO_STAKE_DAPP: '3PEdXcDCs3wzcWQsusvq92cLb7deRpgR4J7',
        PUZZLE_VIRES_LP_TOKENS_POOL: '3P93Kqz8PUbCudvMyjbci54WxQrtBtw4dbZ',
    },
};

export default ADDRESS;
