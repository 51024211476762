import { FC } from 'react';

import { Header, Footer, BaseContainer } from '$/components/Pluto/Layout';

import Intro from './Intro';
import Statistics from './Statistics';
import GetPluto from './GetPluto';
import Onboarding from './Onboarding';
import ReserveCurrency from './ReserveCurrency';
import News from './News';
import st from './Home.module.scss';

const Home: FC = () => (
    <div className={st.home}>
        <Header className={st.header} />

        <BaseContainer>
            <Intro className={st.intro} />
            <Statistics />
            <GetPluto className={st.getPluto} id="getPluto" />
            {/*<Onboarding className={st.onboarding} id="onboarding" />*/}
            <ReserveCurrency className={st.reserveCurrency} id="aboutPluto" />
            <News className={st.news} id="news" />
        </BaseContainer>

        <Footer classNames={st.footer} />
    </div>
);

export default Home;
