import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import icoArrowRight from '$/assets/sprite/arrow-right.svg';
import { scrollToAnchor } from '$/utils/scrollFunctions';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import authService from '$/services/authentication/AuthenticationService';
import { ROUTES } from '$/constants';
import { Button } from '$/components/Pluto/UI';
import { Logo, Wrap } from '$/components/Pluto/Layout';

import MENU_ITEMS from './config';
import st from './Header.module.scss';

interface IProps {
    className?: string;
}

const Header: FC<IProps> = ({ className }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const withConnectLink = pathname !== ROUTES.AUTH;

    const handleClick = useEffectiveCallback((event) => scrollToAnchor({ offset: 20 })(event));

    return (
        <Wrap>
            <header className={cn(st.headerContent, className)}>
                <Logo className={st.logo} />

                {withConnectLink && (
                    <>
                        <nav className={st.nav}>
                            {MENU_ITEMS.map(({ title, to }) => (
                                <a href={`#${to}`} key={title} onClick={handleClick} className={st.navLink}>
                                    {title}
                                </a>
                            ))}
                        </nav>

                        <Button theme="secondary" icon={icoArrowRight} href={ROUTES.DASHBOARD} className={st.btn}>
                            {authService.isLoggedIn() ? t('header.open_dashboard') : t('header.connect_wallet')}
                        </Button>
                    </>
                )}
            </header>
        </Wrap>
    );
};

export default Header;
