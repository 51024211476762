import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ROUTES } from '$/constants';
import { RoundedCard } from '$/components/Pluto';
import { Title, Wrap } from '$/components/Pluto/Layout';
import { Button } from '$/components/Pluto/UI';
import icoArrowRight from '$/assets/sprite/arrow-right.svg';

import st from './News.module.scss';

interface IProps {
    className?: string;
    id?: string;
}

const News: FC<IProps> = ({ className, id }) => {
    const { t } = useTranslation();

    return (
        <section className={cn(st.news, className)} id={id}>
            <Wrap>
                <div className={st.container}>
                    <Title promo as="h2" className={st.mainTitle}>
                        {t('home.news.title')}
                    </Title>

                    <ul className={st.list}>
                        {[
                            {
                                title: 'PLUTO token is LIVE',
                                date: 'July 12, 2022',
                                link: 'https://mirror.xyz/0x409981e2C0370AA0790CCd4AC90F1143D2a01886/losgWeZdYdCddNT0oWQfnGLqM22n8jOh2G5ARCxvosg',
                            },
                            {
                                title: 'Pluto Roadmap',
                                date: 'July 1st, 2022',
                                link: 'https://mirror.xyz/0x409981e2C0370AA0790CCd4AC90F1143D2a01886/o_0EurkKH4Fumfk5u_9P8EP92WwNQ-pwE_LGnduL-EY',
                            },
                        ].map((article) => (
                            <RoundedCard as="li" key={String(article.title)} className={st.item}>
                                <Title promo as="p" size="h2">
                                    {article.title}
                                </Title>

                                <div className={st.itemFooter}>
                                    <p>{article.date}</p>

                                    <Button
                                        href={article.link}
                                        className={st.readBtn}
                                        icon={icoArrowRight}
                                        external
                                        size="small"
                                        theme="secondary"
                                    >
                                        {t('home.news.read')}
                                    </Button>
                                </div>
                            </RoundedCard>
                        ))}
                    </ul>

                    <Button
                        size="medium"
                        external
                        theme="primary"
                        className={st.button}
                        href="https://mirror.xyz/0x409981e2C0370AA0790CCd4AC90F1143D2a01886"
                    >
                        {t('home.news.view_all')}
                    </Button>
                </div>
            </Wrap>
        </section>
    );
};

export default News;
