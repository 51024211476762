import { useEffect, useState } from 'react';

type TUseWindowSize = {
    isTablet: boolean;
    isMobile: boolean;
    width: number;
    height: number;
};

const MOBILE_MEDIA_WIDTH = 768;
const TABLET_MEDIA_WIDTH = 1024;

const useWindowSize = (): TUseWindowSize => {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    const { width, height } = windowSize;

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { isMobile: width <= MOBILE_MEDIA_WIDTH, isTablet: width <= TABLET_MEDIA_WIDTH, width, height };
};

export default useWindowSize;
