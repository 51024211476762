const searchParams = {
    get: (search: string, name: string): string | null => new URLSearchParams(decodeURIComponent(search)).get(name),

    set: (search: string, ...params: Array<string | number>): string => {
        const newSearchParams = new URLSearchParams(decodeURIComponent(search));

        if (params.length % 2 !== 0) {
            throw new Error('Не совпадает количество имён и значений');
        }

        for (let i = 0; i < params.length; i += 2) {
            const name = String(params[i]);
            const value = String(params[i + 1]);

            if (value !== 'undefined') {
                newSearchParams.set(name, value);
            } else {
                newSearchParams.delete(name);
            }
        }

        return newSearchParams.toString();
    },

    delete: (search: string, ...names: string[]): string => {
        const newSearchParams = new URLSearchParams(decodeURIComponent(search));

        names.forEach((name) => newSearchParams.delete(name));

        return newSearchParams.toString();
    },
};

export default searchParams;
