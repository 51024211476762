import { FC, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import cn from 'classnames';

import { apiService, MS_IN_DAY } from '$shared/services/apiService';
import { IAssetFullDetails, IOnboarding, IWithAsset } from '$shared/services/types';

import imgPlutoLogo from '$/assets/img/pluto-logo.png';
import icoRepeat from '$/assets/sprite/repeat.svg';
import authService from '$/services/authentication/AuthenticationService';
import { useUserLpTokens } from '$/hooks/lpTokens';
import { useToggleOpen } from '$/hooks/useToggleOpen';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import { separateThousands, toPercent } from '$/utils/formatNumber';
import { Icon, IssuePlutoCard, Modal, Progress } from '$/components/Pluto';
import { Button } from '$/components/Pluto/UI';

import st from './IssuedPlutoItem.module.scss';

interface IProps extends IOnboarding, IWithAsset {
    currentHeight: number;
    isLoadingPlaceholder?: boolean;
}

const IssuedPlutoItem: FC<IProps> = ({
    isLoadingPlaceholder,
    premium,
    startAmount,
    finishAmount,
    asset,
    daysNumber,
    startDate,
    daysPassed,
    apy,
    endDate,
    startHeight,
    finishHeight,
    currentHeight,
}) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { isOpen, setOpen } = useToggleOpen();
    const { data: lpTokens = [] } = useUserLpTokens();

    const address = authService.getAddress();

    const isFinished = dayjs(endDate).isBefore(dayjs());
    const progressValue = Math.round(((Date.now() - startDate.getTime()) / (daysNumber * MS_IN_DAY)) * 100);
    const withApy = !isLoadingPlaceholder && premium && endDate.getTime() > new Date().getTime();

    let plutoAmount =
        (startAmount + ((finishAmount - startAmount) * (currentHeight - startHeight)) / (finishHeight - startHeight)) /
        10 ** 8;

    if (plutoAmount > finishAmount / 1e8) {
        plutoAmount = finishAmount / 1e8;
    }

    const handleShowModal = useEffectiveCallback(() => setOpen(true));
    const handleHideModal = useEffectiveCallback(() => setOpen(false));

    const handleUpdateOnboardingParameters = useCallback(() => {
        queryClient.invalidateQueries(['onboardinds-list', address]);
    }, [address, queryClient]);

    return (
        <>
            <div className={st.issuedPlutoItem}>
                <div className={st.cell}>
                    <div className={st.dateContainer}>
                        <p>{startDate.toLocaleDateString()}</p>
                    </div>
                </div>

                <div className={cn(st.cell, st.logoCell)}>
                    <div className={st.logoContainer}>
                        <img src={imgPlutoLogo} alt="" className={st.logo} />
                        <p className={st.value}>{plutoAmount.toFixed(2)}</p>
                    </div>
                </div>

                {/*
                <div className={cn(st.cell, st.logoCell, st.profitCell)}>
                    <div className={st.logoContainer}>
                        <img src={imgPlutoLogo} alt="" className={st.logo} />
                        <p className={st.successValue}>+20.1</p>
                    </div>
                </div>
                */}

                <div className={cn(st.cell, st.logoCell, st.exchangeCell)}>
                    <div className={st.logoContainer}>
                        <img src={apiService.getAssetImage(asset.assetId)} alt="" className={st.logo} />
                        <div className={st.exchange}>{asset.name}</div>
                    </div>
                </div>

                <div className={cn(st.cell, st.apyCell)}>
                    {withApy && (
                        <div className={st.apyContainer}>
                            <p>
                                <span className={st.orangeValue}>{`${t('APY')}`}</span>
                                <span className={st.apyValue}>{toPercent(separateThousands(apy))}</span>
                            </p>
                            <div className={st.divider} />
                            <p>
                                <span className={st.successValue}>{`${t('ROI')}`}</span>
                                <span className={st.apyValue}>{toPercent(premium ? premium / 100 : 0)}</span>
                            </p>
                        </div>
                    )}
                </div>

                <div className={st.cell}>
                    <div className={st.progressContainer}>
                        {isFinished && (
                            <div className={st.controls}>
                                <span className={st.finished}>{t('Finished')}</span>

                                <Button theme="orange" size="x-small" onClick={handleShowModal}>
                                    <Icon size="x-small" item={icoRepeat} className={st.icon} />
                                    {t('Repeat')}
                                </Button>
                            </div>
                        )}

                        {!isFinished && (
                            <Progress progress={progressValue} className={st.progress}>
                                {daysPassed > daysNumber ? daysNumber : daysPassed}/{daysNumber} {t('days to finish')}
                            </Progress>
                        )}
                    </div>
                </div>
            </div>

            <Modal withCloseBtn isShown={isOpen} onClose={handleHideModal}>
                <IssuePlutoCard
                    onClose={handleHideModal}
                    onUpdate={handleUpdateOnboardingParameters}
                    lpTokens={lpTokens}
                    defaultSelectedToken={asset as IAssetFullDetails}
                />
            </Modal>
        </>
    );
};

export default IssuedPlutoItem;
