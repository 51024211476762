import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import icoClose from '$/assets/sprite/close.svg';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import { Icon } from '$/components/Pluto';
import { Button } from '$/components/Pluto/UI';

import st from './BuyPlutoPromo.module.scss';

interface IProps {
    className?: string;
}

const BuyPlutoPromo: FC<IProps> = ({ className }) => {
    const { t } = useTranslation();

    const [isShown, setShow] = useState(true);

    const handleClick = useEffectiveCallback(() => setShow(false));

    if (!isShown) {
        return null;
    }

    return (
        <div className={cn(st.buyPluto, className)} hidden>
            <button type="button" className={st.closeBtn} onClick={handleClick}>
                <Icon item={icoClose} />
            </button>

            <p className={st.text}>{t('personal-cabinet.buy-pluto.text')}</p>

            <Button fluid theme="orange">
                {t('personal-cabinet.buy-pluto.btn-text')}
            </Button>
        </div>
    );
};

export default BuyPlutoPromo;
