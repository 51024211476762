import { ParsedQs, stringify } from 'qs';

const getUrl = (route: string, params: ParsedQs = {}): string => {
    const { query } = params;
    let url = route;

    Object.entries(params).forEach(([key, value]) => {
        url = url.replace(`:${key}`, String(value));
    });

    if (query && Object.keys(query).length !== 0) {
        url = `${url}?${stringify(query, { arrayFormat: 'comma', skipNulls: true })}`;
    }

    return url;
};

export default getUrl;
