import { isDevEnv } from '../utils';

abstract class CommonUrlRecord {
    abstract DOMAIN_HOST: string;
    abstract get API_URL(): string;

    get DUXPLORER_URL() {
        return process.env.DUXPLORER_URL || 'https://node2.duxplorer.com';
    }

    get RESERVE_DUXPLORER_URL() {
        return process.env.RESERVE_DUXPLORER_URL || 'https://duxplorer.com';
    }

    get BACKEND_SWOPFI_URL() {
        return process.env.BACKEND_SWOPFI_URL || 'https://backend.swop.fi/';
    }

    get DATA_SERVICE_URL() {
        return process.env.DATA_SERVICE_URL || 'https://api.wavesplatform.com/v0';
    }

    get SWOP_EXCHANGE_URL() {
        return process.env.SWOP_EXCHANGE_URL || 'https://swop.fi/exchange';
    }

    get DEPOSIT_WAVES_URL() {
        return process.env.DEPOSIT_WAVES_URL || 'https://waves.exchange/deposit/WAVES';
    }

    get BUY_WAVES_URL() {
        return process.env.BUY_WAVES_URL || 'https://waves.exchange/buycrypto/buywaves';
    }

    get BUY_EGGS_URL() {
        return process.env.BUY_EGGS_URL || 'https://waves.exchange/buycrypto/buyEGG';
    }

    get DOMAIN_URL() {
        return process.env.DOMAIN_URL || 'https://wavesducks.com';
    }

    get NODE_URL() {
        return process.env.NODE_URL || 'https://nodes.wavesnodes.com';
    }

    get BLOCKCHAIN_URL() {
        if (process.env.BLOCKCHAIN_URL) {
            return process.env.BLOCKCHAIN_URL;
        }

        return `${this.API_URL}/blockchain`;
    }

    get SCAN_URL() {
        return process.env.SCAN_URL || (isDevEnv() ? `http://${this.DOMAIN_HOST}:5001` : `${this.DOMAIN_URL}/api/v0`);
    }

    get PYTHON_MICROSERVICE_URL() {
        return isDevEnv() ? `http://${this.DOMAIN_HOST}:5001` : 'http://scan:5001';
    }

    get CACHE_READER_URL() {
        return (
            process.env.CACHE_READER_URL ||
            (isDevEnv() ? `http://${this.DOMAIN_HOST}:8005` : 'http://cache-reader:8005')
        );
    }

    get CACHE_WRITER_URL() {
        return (
            process.env.CACHE_WRITER_URL ||
            (isDevEnv() ? `http://${this.DOMAIN_HOST}:8006` : 'http://cache-writer:8006')
        );
    }
}

export default CommonUrlRecord;
