import type { RecordByEnv } from '../types';

export type Assets = {
    PLUTO: string;
    LP_VIRES_USDT: string;
    LP_VIRES_USDC: string;
    USDN: string;
    USDC: string;
    USDT: string;
    PARENT_POOL_LP: string;
    WAVES: string;
};

const ASSET: RecordByEnv<Assets> = {
    DEVELOPMENT: {
        PLUTO: 'Ajso6nTTjptu2UHLx6hfSXVtHFtRBJCkKYd5SAyj7zf5',
        LP_VIRES_USDT: '2tVLdi5fQXk2JcuDAojhctnDp5B5PZhNMyj5GUpeC3tZ',
        LP_VIRES_USDC: 'FSRHtSyXRXQjzQLRtmaqFpBDDCNjY8PU8KNtwoGXVBmr',
        USDN: 'DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p',
        USDC: '6XtHjpXbs9RRJP2Sr9GUyVqzACcby9TkThHXnjVC5CDJ',
        USDT: '34N9YcEETLWn93qYQ64EsP1x89tSruJU44RrEMSXXEPJ',
        PARENT_POOL_LP: 'BNXTKjftTy5zZEozFdkqDTTiru7guvunLiCpZuTAaC4h',
        WAVES: 'WAVES',
    },
    PRODUCTION: {
        PLUTO: 'Ajso6nTTjptu2UHLx6hfSXVtHFtRBJCkKYd5SAyj7zf5',
        LP_VIRES_USDT: '2tVLdi5fQXk2JcuDAojhctnDp5B5PZhNMyj5GUpeC3tZ',
        LP_VIRES_USDC: 'FSRHtSyXRXQjzQLRtmaqFpBDDCNjY8PU8KNtwoGXVBmr',
        USDN: 'DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p',
        USDC: '6XtHjpXbs9RRJP2Sr9GUyVqzACcby9TkThHXnjVC5CDJ',
        USDT: '34N9YcEETLWn93qYQ64EsP1x89tSruJU44RrEMSXXEPJ',
        PARENT_POOL_LP: 'BNXTKjftTy5zZEozFdkqDTTiru7guvunLiCpZuTAaC4h',
        WAVES: 'WAVES',
    },
    TEST: {
        PLUTO: 'YQFYoMwARMQzZs3wqucBTdJNWSbsYk3GAw7wsqU4gyA',
        LP_VIRES_USDT: '2tVLdi5fQXk2JcuDAojhctnDp5B5PZhNMyj5GUpeC3tZ',
        LP_VIRES_USDC: 'FSRHtSyXRXQjzQLRtmaqFpBDDCNjY8PU8KNtwoGXVBmr',
        USDN: 'DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p',
        USDC: '6XtHjpXbs9RRJP2Sr9GUyVqzACcby9TkThHXnjVC5CDJ',
        USDT: '34N9YcEETLWn93qYQ64EsP1x89tSruJU44RrEMSXXEPJ',
        PARENT_POOL_LP: '',
        WAVES: 'WAVES',
    },
};

export default ASSET;
