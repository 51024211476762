import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ROUTES } from '$/constants';
import { Button } from '$/components/Pluto/UI';
import { ErrorMessage } from '$/components/Pluto';

import st from './Unavailable.module.scss';
import imgUnavailable from './img/unavailable.png';

interface IProps {
    className?: string;
}

const Unavailable: FC<IProps> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <div className={cn(className)}>
            <ErrorMessage
                img={imgUnavailable}
                title={t('The page is temporarily unavailable')}
                text={
                    <>
                        {t('We are already working on troubleshooting.')}
                        <br />
                        {t('Please try refreshing the page or return to it later.')}
                    </>
                }
            >
                <div className={st.buttons}>
                    <Button href={ROUTES.HOME} theme="gray">
                        {t('Main page')}
                    </Button>

                    <Button theme="orange" onClick={() => null}>
                        {t('Refresh')}
                    </Button>
                </div>
            </ErrorMessage>
        </div>
    );
};

export default Unavailable;
