import { useState, VFC, MouseEvent, useCallback, useRef } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import cn from 'classnames';

import icoArrowExpand from '$/assets/sprite/arrow-expand.svg';
import ChooseToken from '$/pages/IssuePluto/ChooseToken';

import Token from '../Token';
import Icon from '../Icon';
import Modal from '../Modal';
import st from './ChooseTokenButton.module.scss';
import { IAssetFullDetails } from '$shared/services/types';
import { getAssetID } from '$shared/domain/constants';

interface Props {
    className?: string;
    buttonClassName?: string;
    currentOption: IAssetFullDetails;
    lpTokens: IAssetFullDetails[];
    onChoose: (e: MouseEvent<HTMLButtonElement>, token: IAssetFullDetails) => void;
}

const ChooseTokenButton: VFC<Props> = ({ currentOption, lpTokens, className, buttonClassName, onChoose }) => {
    const [isOpen, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useOnclickOutside(() => setOpen(false), {
        refs: [containerRef],
    });

    const handleOpen = useCallback(() => setOpen(true), []);

    const handleClose = useCallback(() => setOpen(false), []);

    return (
        <div ref={containerRef} className={cn(st.container, { [st.isOpen]: isOpen }, className)}>
            <button className={cn(st.current, buttonClassName)} type="button" onClick={handleOpen}>
                <Token token={currentOption} />
                <Icon size="x-small" item={icoArrowExpand} className={st.icon} />
            </button>

            <Modal
                className={st.chooseModal}
                isShown={isOpen}
                onClose={handleClose}
                container={containerRef.current || undefined}
            >
                <ChooseToken
                    lpTokens={lpTokens.filter((asset) => asset.assetId !== getAssetID('PLUTO'))}
                    onClose={handleClose}
                    onChoose={onChoose}
                />
            </Modal>
        </div>
    );
};

export default ChooseTokenButton;
