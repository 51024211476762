import { FC } from 'react';
import cn from 'classnames';

import st from './MobileScrollerContainer.module.scss';

interface IProps {
    className?: string;
    contentClassName?: string;
}

const MobileScrollerContainer: FC<IProps> = ({ className, contentClassName, children }) => (
    <div className={cn(st.container, className)}>
        <div className={st.scroller}>
            <div className={cn(st.content, contentClassName)}>{children}</div>
        </div>
    </div>
);

export default MobileScrollerContainer;
