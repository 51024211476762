import { isDevEnv } from '$shared/utils';

export { default as ADDRESS, type Addresses } from './addresses';
export { default as ASSET, type Assets } from './assets';
export * from './requests';
export * from './common';
export const API_URL = isDevEnv() ? 'http://localhost:8000' : '/';

export const USDN_DECIMALS = 8;
export const PLUTO_DECIMALS = 1e8;
export const PLUTO_DECIMALS_NUMBER = 8;
