import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getAssetID } from '$shared/domain/constants';
import { DummyToken } from '$shared/services/types';

import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import { usePlutoOnboardingParameters } from '$/hooks/onboarding';
import { useUserLpTokens, useUserLpTokensPrice } from '$/hooks/lpTokens';
import searchParams from '$/utils/searchParams';
import { separateThousands, toDollars } from '$/utils/formatNumber';
import { INITIAL_ONBOARDING_PARAMETERS } from '$/constants/onboarding';
import { Title } from '$/components/Pluto/Layout';
import { TabsMenu } from '$/components/Pluto/UI';
import { MobileScrollerContainer } from '$/components/Pluto';

import TokenCard from '../TokenCard';
import { TABS } from './config';
import ETokens from './types';
import st from './LPTokens.module.scss';

interface IProps {
    className?: string;
}

const LPTokens: FC<IProps> = ({ className }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname, search } = useLocation();
    const currentToken = searchParams.get(search, 'token');

    const lpTokensSourceFilterHidden = false;

    const { data: plutoOnboardingParameters = INITIAL_ONBOARDING_PARAMETERS, refetch } = usePlutoOnboardingParameters();
    const { data: lpTokens = [] } = useUserLpTokens();
    const lpTokensPrice = useUserLpTokensPrice();

    if (!currentToken) {
        history.replace(`${pathname}?${searchParams.set(search, 'token', ETokens.ALL)}`);
    }

    const handleTabClick = useEffectiveCallback((slug) => {
        history.replace(`${pathname}?${searchParams.set(search, 'token', slug)}`);
    });

    return (
        <div className={className}>
            <Title as="h2" size="h2" className={st.title}>
                {t('LP tokens')}
            </Title>

            {lpTokensSourceFilterHidden && (
                <TabsMenu className={st.tabs} current={currentToken || 'hz'} items={TABS} onChange={handleTabClick} />
            )}

            <MobileScrollerContainer className={st.balanceContainer} contentClassName={st.balanceContent}>
                <div className={st.balance}>
                    <div className={st.balanceItem}>
                        <p className={st.balanceTitle}>{t('LP Balance')}</p>

                        <Title as="p" size="h3" className={st.balanceValue}>
                            {toDollars(lpTokensPrice)}
                        </Title>
                    </div>

                    <div className={st.balanceItem}>
                        <p className={st.balanceTitle}>{t('Onboarding APY')}</p>

                        <Title as="p" size="h3" className={st.balanceValue}>
                            {`${separateThousands(plutoOnboardingParameters.apy)}%`}
                        </Title>
                    </div>

                    <div className={st.balanceItem}>
                        <p className={st.balanceTitle}>{t('Lock-up period')}</p>

                        <Title as="p" size="h3" className={st.balanceValue}>
                            {plutoOnboardingParameters.period}
                            {t(' days')}
                        </Title>
                    </div>
                </div>
            </MobileScrollerContainer>

            <div className={st.cards}>
                {!lpTokens.length && (
                    <TokenCard
                        lpTokens={lpTokens}
                        key="loading"
                        isLoadingPlaceholder
                        assetDetails={DummyToken}
                        onUpdate={refetch}
                        plutoOnboardingParameters={plutoOnboardingParameters}
                    />
                )}

                {lpTokens
                    .filter(({ assetId }) => assetId !== getAssetID('PLUTO'))
                    .map((assetDetails) => (
                        <TokenCard
                            lpTokens={lpTokens}
                            key={assetDetails.assetId}
                            assetDetails={assetDetails}
                            onUpdate={refetch}
                            plutoOnboardingParameters={plutoOnboardingParameters}
                        />
                    ))}
            </div>
        </div>
    );
};

export default LPTokens;
