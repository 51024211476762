export interface IAssetBalance {
    assetId: string;
    balance: number;
}

export interface IAssetBlockchainDetails {
    assetId: string;
    name: string;
    description: string;
    decimals: number;
    quantity: number;
    minSponsoredAssetFee: number;
    imgSrc: string;
}

export interface IAssetFullDetails extends IAssetBlockchainDetails, IWithPrice, IAssetBalance {}

export interface IWithPrice {
    price: number;
    userBalancePrice: number;
    decimals: number;
}

export interface IEvalResult {
    result: any;
    complexity: number;
    expr: string;
    address: string;
}

export interface IPlutoOnboardingParameters {
    growthFactor: number;
    premium: number;
    apy: number;
    period: number;
    teamAllocation: number;
    buybackRatio: number;
}

export type assetId = string;
export type amount = number;

export interface IMarketPriceEvalResult extends IEvalResult {
    result: {
        type: 'Tuple';
        value: {
            _1: {
                type: 'Array';
                value: any[];
            };
            _2: {
                type: 'Int';
                value: number;
            };
        };
    };
}

export interface IStakedAmountEvalResult extends IEvalResult {
    result: {
        type: 'Tuple';
        value: {
            _1: {
                type: 'Array';
                value: any[];
            };
            _2: {
                type: 'Int';
                value: number;
            };
        };
    };
}

export interface IPlutoTreasuryParameters extends IEvalResult {
    result: {
        type: 'Tuple';
        value: {
            _1: {
                type: 'Array';
                value: any[];
            };
            _2: {
                type: 'String';
                value: string;
            };
        };
    };
}

export interface IOnboardingParametersEvalResult extends IEvalResult {
    result: {
        type: 'Tuple';
        value: {
            _1: {
                type: 'Array';
                value: [];
            };
            _2: {
                type: 'Array';
                value: [
                    {
                        type: 'Int'; // GROWTH FACTOR
                        value: number;
                    },
                    {
                        type: 'Int'; // PREMIUM
                        value: number;
                    },
                    {
                        type: 'Int'; // PERIOD
                        value: number;
                    },
                    {
                        type: 'Int'; // TEAM ALLOCATION
                        value: number;
                    },
                    {
                        type: 'Int'; // BUYBACK RATIO
                        value: number;
                    },
                ];
            };
        };
    };
    complexity: number;
    expr: string;
    address: string;
}

export interface IEvaluateIssuePlutoResult extends IEvalResult {
    result: {
        type: 'Tuple';
        value: {
            _1: {
                type: 'Array';
                value: [];
            };
            _2: {
                type: 'Int';
                value: number;
            };
        };
    };
    complexity: number;
    expr: string;
    address: string;
}

export interface IEvaluateOnboardingsResult extends IEvalResult {
    result: {
        type: 'Tuple';
        value: {
            _1: {
                type: 'Array';
                value: [];
            };
            _2: {
                type: 'String';
                value: string;
            };
        };
    };
    complexity: number;
    expr: string;
    address: string;
}

export interface IOnboarding {
    assetId: string;
    finishAmount: number;
    finishHeight: number;
    globalNum: number;
    premium: number;
    startAmount: number;
    startHeight: number;
    plutoPrice: number;
    apy: number;

    daysPassed: number;
    startDate: Date;
    endDate: Date;
    daysNumber: number;
}

export interface IWithAsset {
    asset: IAssetBlockchainDetails;
}

export interface IBlockHeader {
    timestamp: number;
}

export interface ILandingDetails {
    marketValue: number;
    treasuryValue: number;
    stakingApy: number;
    date: Date;
}

export interface IWavesCapResponseData {
    firstPrice: number;
    lastPrice: number;
    volume: number;
    quoteVolume: number;
    weightedAveragePrice: number;
    txsCount: number;
}

export interface IWavesCapResponse {
    'amount_asset_id': string;
    'data': IWavesCapResponseData;
    'price_asset_id': string;
    'spread': number;
    'firstPrice_tryn': number;
    'lastPrice_tryn': number;
    '24h_vol_tryn': number;
    'firstPrice_brln': number;
    'lastPrice_brln': number;
    '24h_vol_brln': number;
    'firstPrice_gbpn': number;
    'lastPrice_gbpn': number;
    '24h_vol_gbpn': number;
    'firstPrice_ngnn': number;
    'lastPrice_ngnn': number;
    '24h_vol_ngnn': number;
    'firstPrice_uahn': number;
    'lastPrice_uahn': number;
    '24h_vol_uahn': number;
    'firstPrice_jpyn': number;
    'lastPrice_jpyn': number;
    '24h_vol_jpyn': number;
    'firstPrice_cnyn': number;
    'lastPrice_cnyn': number;
    '24h_vol_cnyn': number;
    'firstPrice_rubn': number;
    'lastPrice_rubn': number;
    '24h_vol_rubn': number;
    'firstPrice_eurn': number;
    'lastPrice_eurn': number;
    '24h_vol_eurn': number;
    'firstPrice_usd-n': number;
    'lastPrice_usd-n': number;
    '24h_vol_usd-n': number;
    'firstPrice_waves': number;
    'lastPrice_waves': number;
    '24h_vol_waves': number;
    'firstPrice_btc': number;
    'lastPrice_btc': number;
    '24h_vol_btc': number;
    'firstPrice_eth': number;
    'lastPrice_eth': number;
    '24h_vol_eth': number;
    'bid_price': number;
    'ask_price': number;
}

export const DummyToken = {
    assetId: '',
    name: 'Loading...',
    description: 'loading...',
    quantity: 0,
    minSponsoredAssetFee: 0,
    imgSrc: '/assets/img/pluto-logo.png',
    price: 0,
    userBalancePrice: 0,
    decimals: 0,
    balance: 0,
};
