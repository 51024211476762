import { FC } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import cn from 'classnames';

import icoCopy from '$/assets/sprite/copy.svg';
import icoLogout from '$/assets/sprite/logout.svg';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import authService from '$/services/authentication/AuthenticationService';
import formatAddress from '$/utils/formatAddress';
import copyText from '$/browser/clipboard';
import { ROUTES } from '$/constants';
import { Icon } from '$/components/Pluto';

import st from './UserBlock.module.scss';

interface IProps {
    className?: string;
}

const UserBlock: FC<IProps> = ({ className }) => {
    const history = useHistory();

    const { address = '' } = authService.getUser() || {};

    const handleLogout = useEffectiveCallback(() => {
        authService.logout();
        history.replace(ROUTES.AUTH);
    });

    const handleCopyText = useEffectiveCallback(() => copyText(address));

    if (!address) {
        return <Redirect to={ROUTES.AUTH} />;
    }

    return (
        <div className={cn(st.userBlock, className)}>
            <span className={st.userAddress}>{formatAddress(address, 8, 8)}</span>

            <button type="button" className={st.btn} onClick={handleCopyText}>
                <Icon size="small" item={icoCopy} />
            </button>

            <button type="button" className={st.btn} onClick={handleLogout}>
                <Icon size="small" item={icoLogout} />
            </button>
        </div>
    );
};

export default UserBlock;
