import { FC } from 'react';
import cn from 'classnames';

import st from './RoundedCard.module.scss';

interface IProps {
    as?: 'div' | 'li';
    className?: string;
}

const RoundedCard: FC<IProps> = ({ as = 'div', className, children }) => {
    const Component = as;

    return <Component className={cn(st.card, className)}>{children}</Component>;
};

export default RoundedCard;
