const log = (...args) => console.error(...args);

export const lStorage = {
    set: (key, value) => {
        try {
            global.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            log('localStorage: ', error);
        }
    },

    get: (key) => {
        try {
            const item = global.localStorage.getItem(key);

            return item ? JSON.parse(item) : undefined;
        } catch (error) {
            log('localStorage: ', error);
            return null;
        }
    },
};
