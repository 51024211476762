import { FC } from 'react';
import cn from 'classnames';

import st from './SidebarBottom.module.scss';

interface IProps {
    className?: string;
}

const SidebarBottom: FC<IProps> = ({ className, children }) => (
    <div className={cn(st.sidebarBottom, className)}>{children}</div>
);

export default SidebarBottom;
