import { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import icoGrowth from '$/assets/sprite/growth.svg';
import plutoLogo from '$/assets/img/pluto-logo.png';
import { usePlutoPrice, usePlutoQuantity, usePlutoTreasuryValue } from '$/hooks/pluto';
import { usePlutoBalance } from '$/hooks/balances';
import { toDollars } from '$/utils/formatNumber';
import { getPlutoBalance, getPlutoDollarsBalance } from '$/utils/balances';
import { Icon } from '$/components/Pluto';
import { Overlay } from '$/components/Pluto/Layout';

import Navigation from '../Navigation';
import st from './MobileSidebar.module.scss';

const MobileSidebar = forwardRef<HTMLDivElement>((_, ref) => {
    const { t } = useTranslation();

    const { data: { balance: plutoBalance = 0 } = {} } = usePlutoBalance();
    const { data: { lastPrice: plutoPrice = 0 } = {} } = usePlutoPrice();
    const { data: plutoQuantity = 0 } = usePlutoQuantity();
    const { data: plutoTreasuryValue = 0 } = usePlutoTreasuryValue();

    const content = (
        <Overlay>
            <div className={st.mobileSidebar} ref={ref}>
                <Navigation />

                <div className={st.balance}>
                    {t('Total balance')}

                    <div className={st.balanceValues}>
                        <div className={st.balanceValue}>
                            <img src={plutoLogo} className={st.plutoLogo} alt="" />
                            {getPlutoBalance(plutoBalance)}
                        </div>

                        {getPlutoDollarsBalance(plutoBalance, plutoPrice)}
                    </div>
                </div>

                <div className={cn(st.price, st.market)}>
                    <Icon item={icoGrowth} className={st.icoGrowth} />
                    {t('Market price')}
                    <span className={st.priceValue}>{toDollars(plutoPrice)}</span>
                </div>

                <div className={cn(st.price, st.backed)}>
                    <Icon item={icoGrowth} className={st.icoGrowth} />
                    {t('Backed price')}
                    <span className={st.priceValue}>{toDollars(plutoTreasuryValue / 1e6 / (plutoQuantity / 1e8))}</span>
                </div>
            </div>
        </Overlay>
    );

    return createPortal(content, document.body);
});

export default MobileSidebar;
