import { FC } from 'react';
import cn from 'classnames';

import st from './Badge.module.scss';

interface IProps {
    className?: string;
    theme?: 'gray';
}

const Badge: FC<IProps> = ({ className, children, theme = 'gray' }) => (
    <span className={cn(st.badge, [st[theme]], className)}>{children}</span>
);

export default Badge;
