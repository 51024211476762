import { FC } from 'react';
import cn from 'classnames';

import { useLockBodyScroll } from '$/hooks/useLockBodyScroll';

import st from './Overlay.module.scss';

interface IProps {
    className?: string;
}

const Overlay: FC<IProps> = ({ className, children }) => {
    useLockBodyScroll(true);

    return (
        <aside className={cn(st.overlay, className)}>{children}</aside>
    );
}

export default Overlay;
