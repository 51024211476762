import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { getAssetID } from '$shared/domain/constants';
import { apiService } from '$shared/services/apiService';

import icoInfo from '$/assets/sprite/info.svg';
import { useToggleOpen } from '$/hooks/useToggleOpen';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import { separateThousands, toDollars } from '$/utils/formatNumber';
import authService from '$/services/authentication/AuthenticationService';
import { Icon, Modal } from '$/components/Pluto';
import { Button, Tooltip } from '$/components/Pluto/UI';
import { Title } from '$/components/Pluto/Layout';

import IssuePluto from '../IssuePluto';
import StakeModal from '../StakeModal';
import st from './StatisticsItem.module.scss';

interface IProps {
    apy?: number;
    changes?: number;
    className?: string;
    dollars: number;
    percent: number;
    pluto: number;
    slug: string;
    title: string;
    afterUpdate: () => void;
}

const StatisticsItem: FC<IProps> = ({ apy, afterUpdate, changes, className, dollars, percent, pluto, slug, title }) => {
    const { t } = useTranslation();

    const [isStake, setStake] = useState(false);
    const [stakedAmount, setStakedAmount] = useState(0);
    const [plutoBalance, setPlutoBalance] = useState(0);
    const [plutoPrice, setPlutoPrice] = useState(0);

    const updateData = () => {
        apiService.getStakedBalance(authService.getAddress()).then((res) => {
            setStakedAmount(res);
        });

        authService.getLoggedInUserPortfolio().then((res) => {
            const fetchedPlutoBalance = res.find((assetDetails) => assetDetails.assetId === getAssetID('PLUTO'));
            if (fetchedPlutoBalance) {
                setPlutoBalance(fetchedPlutoBalance.balance);
            }
        });

        apiService.getPlutoPriceDetails().then((res) => {
            setPlutoPrice(res.lastPrice);
        });
    };

    useEffect(updateData, []);

    const { isOpen: isIssuePlutoModalOpen, setOpen: setIssuePlutoModalOpen } = useToggleOpen();
    const { isOpen: isStakeModalOpen, setOpen: setStakeModalOpen } = useToggleOpen();

    const isStaking = slug === 'staking';

    const handleCloseIssuePlutoModal = useEffectiveCallback(() => setIssuePlutoModalOpen(false));

    const handleOpenStakeModal = useEffectiveCallback((stake) => () => {
        setStake(stake);
        setStakeModalOpen(true);
    });

    const handleCloseStakeModal = useEffectiveCallback(() => setStakeModalOpen(false));

    return (
        <div className={cn(st.statisticsItem, className)}>
            <div className={st.content}>
                <div className={st.center}>
                    <p className={st.title}>{title}</p>

                    <Title as="p" size="h2" className={st.pluto}>
                        {separateThousands(pluto)}
                    </Title>

                    <p className={st.dollars}>{toDollars(dollars)}</p>

                    {changes && (
                        <p className={cn(st.changes, { [st.income]: changes >= 0, [st.fall]: changes < 0 })}>
                            {changes > 0 ? '+' : '-'}&thinsp;{toDollars(Math.abs(changes))}
                            {` (${percent}%)`}
                        </p>
                    )}

                    {apy && (
                        <div className={st.apy}>
                            <span className={st.apyText}>{`APY ${apy}%`}</span>

                            <Tooltip
                                containerClassName={st.tooltip}
                                position="bottom"
                                target={<Icon size="x-small" item={icoInfo} />}
                            >
                                <p>
                                    {t('Consists of unconditional treasury yield income and incentive staking reward')}
                                </p>
                            </Tooltip>
                        </div>
                    )}
                </div>

                {isStaking && (
                    <div className={st.right}>
                        <div className={st.buttons}>
                            <Button theme="gray" size="small" className={st.btn} onClick={handleOpenStakeModal(true)}>
                                {t('Stake')}
                            </Button>

                            <Button theme="gray" size="small" className={st.btn} onClick={handleOpenStakeModal(false)}>
                                {t('Unstake')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            <Modal withCloseBtn isShown={isIssuePlutoModalOpen} onClose={handleCloseIssuePlutoModal}>
                <IssuePluto onClick={handleCloseIssuePlutoModal} />
            </Modal>

            <Modal withCloseBtn isShown={isStakeModalOpen} onClose={handleCloseStakeModal}>
                <StakeModal
                    isStake={isStake}
                    plutoBalance={plutoBalance}
                    plutoPrice={plutoPrice}
                    stakedAmount={stakedAmount}
                    afterCall={() => {
                        handleCloseStakeModal();
                        updateData();
                        afterUpdate();
                    }}
                />
            </Modal>
        </div>
    );
};

export default StatisticsItem;
