import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import imgPlutoLogo from '$/assets/img/pluto-logo.png';
import icoGrowth from '$/assets/sprite/growth.svg';
import { usePlutoBalance } from '$/hooks/balances';
import { usePlutoPrice, usePlutoQuantity, usePlutoTreasuryValue } from '$/hooks/pluto';
import { toDollars } from '$/utils/formatNumber';
import { getPlutoBalance, getPlutoDollarsBalance } from '$/utils/balances';
import { Icon } from '$/components/Pluto';

import st from './InfoPanel.module.scss';

interface IProps {
    className?: string;
}

const InfoPanel: FC<IProps> = ({ className }) => {
    const { t } = useTranslation();

    const { data: { balance: plutoBalance = 0 } = {} } = usePlutoBalance();
    const { data: plutoQuantity = 0 } = usePlutoQuantity();
    const { data: plutoTreasuryValue = 0 } = usePlutoTreasuryValue();
    const { data: { lastPrice: plutoPrice = 0 } = {} } = usePlutoPrice();

    return (
        <aside className={cn(st.infoPanel, className)}>
            <div className={st.logoContainer}>
                <p className={cn(st.label, st.totalPluto)}>Total PLUTO:</p>

                <div className={st.logoContainer}>
                    <img src={imgPlutoLogo} alt="" className={st.logo} />
                    <p className={cn(st.value, st.pluto)}>{getPlutoBalance(plutoBalance)}</p>
                </div>

                <p className={st.dollars}>{getPlutoDollarsBalance(plutoBalance, plutoPrice)}</p>
            </div>

            <div className={st.priceContainer}>
                <p className={st.label}>
                    <Icon item={icoGrowth} className={cn(st.ico, st.icoBacked)} />
                    {t('Backed price')}:
                </p>
                <p className={st.value}>{toDollars(plutoTreasuryValue / 1e6 / (plutoQuantity / 1e8))}</p>
            </div>

            <div className={st.priceContainer}>
                <p className={st.label}>
                    <Icon item={icoGrowth} className={cn(st.ico, st.icoMarket)} />
                    {t('Market price')}:
                </p>
                <p className={st.value}>{toDollars(plutoPrice)}</p>
            </div>

            <div className={st.priceContainer}>
                <p className={st.label}>Total supply:</p>
                <p className={st.value}>{toDollars(plutoQuantity / 1e8)} </p>
            </div>

            <div className={st.priceContainer}>
                <p className={st.label}>Treasury size:</p>
                <p className={st.value}>{toDollars(plutoTreasuryValue / 1e6)} </p>
            </div>
        </aside>
    );
};

export default InfoPanel;
