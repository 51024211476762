import { useLayoutEffect } from 'react';

export function useLockBodyScroll(lockScroll) {
    useLayoutEffect(() => {
        const {
            body: { classList },
        } = document;

        if (lockScroll) {
            classList.add('body-lock');
        }

        return () => {
            classList.remove('body-lock');
        };
    }, [lockScroll]);
}
