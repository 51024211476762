import { forwardRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import icoCopy from '$/assets/sprite/copy.svg';
import icoLogout from '$/assets/sprite/logout.svg';
import icoClose from '$/assets/sprite/close.svg';
import icoCheck from '$/assets/sprite/check.svg';
import authService from '$/services/authentication/AuthenticationService';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import formatAddress from '$/utils/formatAddress';
import { ROUTES } from '$/constants';
import copyText from '$/browser/clipboard';
import { Icon } from '$/components/Pluto';
import { Overlay } from '$/components/Pluto/Layout';

import st from './MobileWalletInfo.module.scss';

const MobileWalletInfo = forwardRef<HTMLDivElement, { onClose: () => void }>(({ onClose }, ref) => {
    const history = useHistory();

    const [isCopied, setCopied] = useState(false);

    const { address = '' } = authService.getUser() || {};

    const handleLogout = useEffectiveCallback(() => {
        authService.logout();
        history.replace(ROUTES.AUTH);
    });

    const handleCopyText = useEffectiveCallback(() => {
        copyText(address).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
        });
    });

    return (
        <Overlay className={st.overlay}>
            <div ref={ref} className={st.walletInfo}>
                <button type="button" className={st.closeBtn} onClick={onClose}>
                    <Icon size="small" item={icoClose} />
                </button>

                <p className={st.address}>{formatAddress(address, 8, 8)}</p>

                <div className={st.action}>
                    <button type="button" className={st.btn} onClick={handleCopyText}>
                        <Icon size="small" item={icoCopy} className={st.icon} />
                        Copy address
                        <span className={cn(st.icoCheckContainer, { [st.shown]: isCopied })}>
                            <Icon size="x-small" item={icoCheck} />
                        </span>
                    </button>
                </div>

                <div className={st.action}>
                    <button type="button" className={st.btn} onClick={handleLogout}>
                        <Icon size="small" item={icoLogout} className={st.icon} />
                        Disconnect
                    </button>
                </div>
            </div>
        </Overlay>
    );
});

export default MobileWalletInfo;
