import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { PLUTO_DECIMALS } from '$shared/constants';

import { usePlutoPrice } from '$/hooks/pluto';
import { useLandingParameters } from '$/hooks/parameters';
import { usePlutoBalance, useStakedBalance } from '$/hooks/balances';
import { getPlutoBalance, getPlutoDollarsBalance } from '$/utils/balances';
import { toPercentage } from '$/utils/formatNumber';
import { MobileScrollerContainer } from '$/components/Pluto';

import StatisticsItem from '../StatisticsItem';
import st from './Statistics.module.scss';

interface IProps {
    className?: string;
}

const Statistics: FC<IProps> = ({ className }) => {
    const { t } = useTranslation();

    const { data: { balance: plutoBalance = 0 } = {}, refetch: updatePlutoBalance } = usePlutoBalance();
    const { data: { stakingApy = 0 } = {}, refetch: updateLandingParameters } = useLandingParameters();
    const { data: stakedBalance = 0, refetch: updateStakedBalance } = useStakedBalance();
    const { data: { lastPrice: plutoPrice = 0, firstPrice = 0 } = {}, refetch: updatePlutoPrice } = usePlutoPrice();

    const plutoPriceChanges = plutoPrice - firstPrice;
    const plutoPriceChangesPercentage = toPercentage(((plutoPrice - firstPrice) / firstPrice) * 100);

    const updateData = useCallback(() => {
        updatePlutoBalance();
        updateLandingParameters();
        updateStakedBalance();
        updatePlutoPrice();
    }, [updateLandingParameters, updatePlutoBalance, updatePlutoPrice, updateStakedBalance]);

    return (
        <MobileScrollerContainer
            className={cn(st.mobileScroller, className)}
            contentClassName={st.mobileScrollerContent}
        >
            <div className={st.statistics}>
                <StatisticsItem
                    slug="wallet"
                    title={t('PLUTO in the wallet')}
                    pluto={getPlutoBalance(plutoBalance)}
                    dollars={getPlutoDollarsBalance(plutoBalance, plutoPrice)}
                    changes={getPlutoBalance(plutoBalance) * plutoPriceChanges}
                    percent={parseFloat(plutoPriceChangesPercentage)}
                    afterUpdate={updateData}
                />

                <StatisticsItem
                    slug="staking"
                    title="Staking"
                    pluto={stakedBalance / PLUTO_DECIMALS}
                    dollars={(stakedBalance / PLUTO_DECIMALS) * plutoPrice}
                    percent={plutoPriceChanges}
                    afterUpdate={updateData}
                    apy={stakingApy / 10000}
                />
            </div>
        </MobileScrollerContainer>
    );
};

export default Statistics;
