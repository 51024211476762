import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import getUrl from '$/utils/getUrl';
import { PERSONAL_CABINET, ROUTES } from '$/constants';
import { BaseContainer, Layout, Sidebar } from '$/components/Pluto/Layout';
import ApiErrorBoundary from '$/components/Pluto/ApiErrorBoundary';
import LoadingTab from '$/pages/PersonalCabinet/LoadingTab';
import PAGES from '$/pages/PersonalCabinet/config';
import authService from '$/services/authentication/AuthenticationService';

import TabContent from './TabContent';
import CabinetHeader from './CabinetHeader';
import SidebarContent from './SidebarContent';
import MobileHeader from './MobileHeader';

const PersonalCabinet: FC = () => {
    const { url } = useRouteMatch();

    if (authService.getAddress() === undefined) {
        return <Redirect to={ROUTES.AUTH} />;
    }

    return (
        <Layout>
            <MobileHeader />

            <Sidebar>
                <SidebarContent />
            </Sidebar>

            <BaseContainer flex>
                <ApiErrorBoundary>
                    <React.Suspense fallback={<LoadingTab />}>
                        <CabinetHeader />

                        <TabContent>
                            <Switch>
                                {Object.entries(PAGES).map(([tab, component]) => (
                                    <Route key={tab} path={`${url}/${tab}`} component={() => component} />
                                ))}
                                <Redirect to={getUrl(ROUTES.DASHBOARD_INNER, { tab: PERSONAL_CABINET.ISSUE_PLUTO })} />
                            </Switch>
                        </TabContent>
                    </React.Suspense>
                </ApiErrorBoundary>
            </BaseContainer>
        </Layout>
    );
};

export default PersonalCabinet;
