const copyText = (text: string): Promise<void> => {
    if (navigator.clipboard && window.isSecureContext) {
        return Promise.resolve(navigator.clipboard.writeText(text)).catch((e) => {
            throw new Error(e);
        });
    }

    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();

    return new Promise<void>((resolve, reject) => {
        document.execCommand('copy') ? resolve() : reject();
    })
        .catch((exception) => console.warn('Copy to clipboard failed.', exception))
        .finally(() => document.body.removeChild(textarea));
};

export default copyText;
